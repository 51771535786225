import { Theme } from '@mui/material';
import { makeStyles } from 'mui-styles';

export const useStyles = makeStyles((theme: Theme) => ({
  textBox: {
    color: '#65707e',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    marginBottom: '8px'
  }
}));
