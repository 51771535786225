import React from "react";
import "./styles/index.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";

import "./i18n";
import "moment-timezone";
import "moment/locale/es";

import axios from "axios";
import Moment from "react-moment";

axios.defaults.baseURL = process.env.REACT_APP_HOST_API_URL;

// Set the timezone for every instance.
Moment.globalTimezone = "Europe/Madrid";

// Set the output timezone for local for every instance.
Moment.globalLocal = true;

Moment.globalLocale = "es";

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <BrowserRouter>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
