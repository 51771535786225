import axios from "axios";
import { ResponsePaymentIntent } from "../models/response_model";

class PaymentService {
  async getPaymentIntents(id?: string): Promise<ResponsePaymentIntent> {
    let result: ResponsePaymentIntent = {};

    await axios
      .get(`payment/intent/${id}`)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }
}

export default PaymentService;
