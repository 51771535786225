import { Credit } from "src/core/models/credit_model";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import * as React from "react";
import { useEffect, useState } from "react";
import "@stripe/stripe-js";
import UiLoadingButton from "src/components/LoadingButtons";
import OrderService from "src/core/services/order_service";
import { Order } from "src/core/models/order_model";
import { Card, Chip, Grid, alpha } from "@mui/material";
import UiButton from "src/components/Buttons";
import { PaymentBloc } from "src/core/bloc/payment/payment_bloc";
import { PaymentIntentCredit } from "src/core/models/user_model";
import PaymentService from "src/core/services/payment_service";
import ContainerContent from "src/components/Container/Content";
import { useMyCreditBloc, useOrderBloc } from "src/core/provider/Provider";
import { useSnackbar } from "src/core/contexts/SnackbarProvider";
import { useTranslation } from "react-i18next";
import { TextLabel } from "src/components/Label";

interface BuyContentProps {
  data?: Credit;
  setOpen: (v: boolean) => void;
}

const CheckoutForm = ({ data, setOpen }: BuyContentProps) => {
  const bloc = new PaymentBloc();
  const blocOrder = useOrderBloc();
  const myCredit = useMyCreditBloc();
  const [status, setStatus] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setStatus(undefined);
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    stripe
      .confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: { return_url: window.location.href },
      })
      .then(async (res) => {
        if (res.paymentIntent) {
          const order: Order = {
            amount: data?.amount ?? 0,
            tax: data?.tax,
            currency: res.paymentIntent?.currency,
            payment_intent_id: res.paymentIntent?.id,
            payment_status: res.paymentIntent?.status,
            price: data?.price,
            status: res.paymentIntent.status,
            credit_id: data?.id,
          };
          const resPay = await blocOrder.addOrder(order);

          if (resPay === "success") {
            snackbar.success(
              `Gracias por su compra. Se han agregado ${data?.amount} créditos a su cuenta.`
            );
            myCredit.getMyCredit();
          } else {
            if (resPay !== undefined) {
              if (res !== undefined) {
                snackbar.error(t(`error.${res}`));
              }
            }
          }
          setLoading(false);
          setOpen(false);
        } else {
          if (res.error) {
            setStatus(res.error.message);
          }
          setLoading(false);
        }
        bloc.removePaymentIntent(data?.id);
      })
      .catch((err) => {
        setStatus("Ocurrió un problema, ¡vuelve a intentarlo!");
        bloc.removePaymentIntent(data?.id);
        setLoading(false);
      });
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        textAlign: "center",
        marginLeft: 4,
        marginRight: 4,
      }}
    >
      {data !== undefined ? (
        <Grid container textAlign="start" marginBottom={1} marginTop={-1}>
          <Grid item xs={6}>
            <TextLabel label="Creditos" text={data?.amount} />
          </Grid>
          <Grid item xs={6}>
            <TextLabel label="Precio" text={"€ " + data?.price} />
          </Grid>
        </Grid>
      ) : undefined}
      {status ? (
        <div
          style={{
            color: "white",
            backgroundColor: "#FF0033",
            width: "100%",
            opacity: 0.8,
            marginBottom: 10,
            textAlign: "center",
            maxWidth: 300,
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 15,
            paddingRight: 15,
            borderRadius: 15,
          }}
        >
          <p>{status}</p>
        </div>
      ) : undefined}
      <PaymentElement />
      <Grid container paddingTop={3} spacing={2}>
        <Grid item xs={6} textAlign="center">
          <UiButton
            fullWidth
            variant="outlined"
            text="Cancelar"
            disabled={loading}
            onClick={() => setOpen(false)}
          />
        </Grid>
        <Grid item xs={6} textAlign="center">
          <UiLoadingButton
            type="submit"
            loading={loading}
            disabled={!stripe || loading}
            text="Pagar"
          />
        </Grid>
      </Grid>
    </form>
  );
};

const BuyContent = ({ data, setOpen }: BuyContentProps) => {
  const bloc = new PaymentBloc();
  const promiseStripe = loadStripe(process.env.REACT_APP_PUBLISH_KEY ?? "");
  const [payment, setPayment] = useState<PaymentIntentCredit>();
  const [loading, setLoading] = useState<boolean>(false);

  const initData = async () => {
    setLoading(true);
    try {
      const res = await bloc.getPaymentIntent(data?.id);
      if (res) {
        setPayment(res);
      } else {
        const response = await new PaymentService().getPaymentIntents(data?.id);
        if (response.code === "success") {
          if (response.data) {
            setPayment(response.data);
            bloc.addPaymentIntent(response.data);
          }
        } else {
          bloc.removePaymentIntent(data?.id);
        }
      }
    } catch (err) {}
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Elements
      stripe={payment != undefined ? promiseStripe : null}
      options={{
        clientSecret: payment?.client_secret,
      }}
    >
      <CheckoutForm
        data={payment != undefined ? data : undefined}
        setOpen={setOpen}
      />
    </Elements>
  );
};

export default BuyContent;
