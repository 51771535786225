import { useState } from "react";
import { MuiForm } from "react-mui-form-validator";
import { Grid } from "@mui/material";
import { UiTextField } from "src/components/TextField";
import { useSnackbar } from "src/core/contexts/SnackbarProvider";
import UiButton from "src/components/Buttons";
import { AddCreditUserContentProps } from "src/core/types/content_type";
import { useCreditBloc } from "src/core/provider/Provider";
import UiLoadingButton from "src/components/LoadingButtons";
import { useTranslation } from "react-i18next";
import { TextLabel } from "src/components/Label";
import CreditService from "src/core/services/credit_service";

const AddCreditUser = (props: AddCreditUserContentProps) => {
  const { setOpen, data } = props;
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const [amount, setAmount] = useState<number | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleAddUpdateCredit = async () => {
    setLoading(true);
    if (data) {
      const res = await new CreditService().addUserCredit(data.uid, amount);

      if (res.code === "success") {
        snackbar.success(`Se ha agregado ${amount} creditos a ${data.email}`);
      } else {
        snackbar.error(t(`error.${res}`));
      }
      setOpen(false);
    }
    setLoading(false);
  };

  return (
    <MuiForm
      onSubmit={handleAddUpdateCredit}
      style={{
        maxWidth: 350,
        padding: 0,
      }}
      sx={{
        minWidth: 300,
      }}
    >
      <Grid container direction={"column"} spacing={1} marginBottom={1.5}>
        <Grid margin={0} item xs={12}>
          <TextLabel label="Nombre" text={data?.name} />
        </Grid>
        <Grid margin={0} item>
          <TextLabel label="Correo electrónico" text={data?.email} />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          <UiTextField
            fullWidth
            value={amount}
            label="Cantidad de créditos"
            placeholder="0"
            onChange={(e: any) => setAmount(e.target.value)}
            required
            errorMessage="Ingrese cantidad"
          />
        </Grid>
      </Grid>

      <Grid container paddingTop={3} spacing={2}>
        <Grid item xs={6} textAlign="center">
          <UiButton
            fullWidth
            variant="outlined"
            text="Cancelar"
            onClick={() => setOpen(false)}
          />
        </Grid>
        <Grid item xs={6} textAlign="center">
          <UiLoadingButton
            fullWidth
            loading={loading}
            text={"Agregar"}
            type="submit"
          />
        </Grid>
      </Grid>
    </MuiForm>
  );
};

export default AddCreditUser;
