import Container from "src/components/Container";
import { Box } from "@mui/material";
import UiButton from "src/components/Buttons";
import { useState, useEffect } from "react";
import { ResetDialog, UiDialog } from "src/components/Dialog";
import Content from "./components/Content";
import { useLineBloc, useUserLineBloc } from "src/core/provider/Provider";
import LineList from "./components/List";
import { useSnackbar } from "src/core/contexts/SnackbarProvider";
import { useTranslation } from "react-i18next";
import { BlocBuilder } from "react-stream-bloc";
import { LineState } from "src/core/bloc/line/line_state";

const LineContent = () => {
  const bloc = useLineBloc();
  const userLineBloc = useUserLineBloc();
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [resetDialog, setResetDialog] = useState<boolean>(false);

  const initData = () => {
    if (bloc.lines.length === 0) {
      bloc.getLines();
    }
    if (userLineBloc.lines.length === 0) {
      userLineBloc.getLines();
    }
  };

  const handleAdd = () => {
    setOpenDialog(true);
  };

  const handleReset = () => {
    setResetDialog(true);
  };

  const onReset = async () => {
    setResetDialog(false);
    const res = await bloc.resetLines();
    if (res !== "success") {
      snackbar.error(t(`error.${res}`));
    }
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Container helmetTitle="Lineas" title="Lineas">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="end"
        justifyItems="center"
        style={{
          width: "100%",
        }}
      >
        <BlocBuilder
          bloc={bloc}
          builder={(state: LineState) => (
            <UiButton
              text="Restablecer líneas"
              onClick={handleReset}
              variant="outlined-primary"
              disabled={state.data.length === 0}
              sx={{ mr: 2 }}
            />
          )}
        />
        <UiButton text="Agregar lineas" onClick={handleAdd} />
      </Box>
      <LineList bloc={bloc} />
      <UiDialog open={openDialog} maxWidth="sm">
        <Content
          setOpen={(v: boolean) => setOpenDialog(v)}
          bloc={bloc}
          userBloc={userLineBloc}
        />
      </UiDialog>
      <ResetDialog
        open={resetDialog}
        setOpen={() => setResetDialog(false)}
        onClick={onReset}
        title="¿Estás seguro de restablecer las líneas?"
      />
    </Container>
  );
};

export default LineContent;
