import * as React from "react";
import { Button } from "@mui/material";
import { ButtonProps } from "src/core/types/button_type";
import { useStyles } from "./styles";
import { Link as RouterLink } from "react-router-dom";

const UiButton = (props: ButtonProps) => {
  const {
    type,
    text,
    variant,
    onClick,
    fullWidth,
    to,
    children,
    sx,
    disabled,
  } = props;
  const classes = useStyles();

  switch (variant) {
    case "outlined":
      return (
        <Button
          component={to !== undefined ? RouterLink : "button"}
          to={to}
          type={type}
          variant="outlined"
          className={classes.outlined}
          disabled={disabled}
          onClick={onClick}
          style={{
            width: fullWidth ? "100%" : "auto",
            minWidth: fullWidth ? "auto" : 140,
          }}
          sx={sx}
        >
          {children !== undefined ? children : text}
        </Button>
      );
    case "outlined-primary":
      return (
        <Button
          component={to !== undefined ? RouterLink : "button"}
          to={to}
          type={type}
          variant="outlined"
          className={classes.outlinedPrimary}
          disabled={disabled}
          onClick={onClick}
          style={{
            width: fullWidth ? "100%" : "auto",
            minWidth: fullWidth ? "auto" : 140,
          }}
          sx={sx}
        >
          {children !== undefined ? children : text}
        </Button>
      );
    case "contained":
      return (
        <Button
          component={to !== undefined ? RouterLink : "button"}
          to={to}
          type={type}
          variant="contained"
          className={classes.contained}
          onClick={onClick}
          disabled={disabled}
          style={{
            width: fullWidth ? "100%" : "auto",
            minWidth: fullWidth ? "auto" : 140,
          }}
          sx={sx}
        >
          {children !== undefined ? children : text}
        </Button>
      );
    case "cancel":
      return (
        <Button
          component={to !== undefined ? RouterLink : "button"}
          to={to}
          type={type}
          variant="outlined"
          className={classes.cancel}
          onClick={onClick}
          disabled={disabled}
          style={{
            width: fullWidth ? "100%" : "auto",
            minWidth: fullWidth ? "auto" : 140,
          }}
          sx={sx}
        >
          {children !== undefined ? children : text}
        </Button>
      );
    case "delete":
      return (
        <Button
          component={to !== undefined ? RouterLink : "button"}
          to={to}
          type={type}
          variant="contained"
          color="primary"
          disabled={disabled}
          className={classes.delete}
          onClick={onClick}
          style={{
            width: fullWidth ? "100%" : "auto",
            minWidth: fullWidth ? "auto" : 140,
          }}
          sx={sx}
        >
          {children !== undefined ? children : text}
        </Button>
      );
    case "text":
      return (
        <Button
          component={to !== undefined ? RouterLink : "button"}
          to={to}
          type={type}
          variant="text"
          disabled={disabled}
          className={classes.outlined}
          onClick={onClick}
          style={{
            width: fullWidth ? "100%" : "auto",
            minWidth: fullWidth ? "auto" : 140,
          }}
          sx={sx}
        >
          {children !== undefined ? children : text}
        </Button>
      );
    default:
      return (
        <Button
          component={to !== undefined ? RouterLink : "button"}
          to={to}
          type={type}
          variant="contained"
          disabled={disabled}
          className={classes.contained}
          onClick={onClick}
          style={{
            width: fullWidth ? "100%" : "auto",
            minWidth: fullWidth ? "auto" : 140,
          }}
          sx={sx}
        >
          {children !== undefined ? children : text}
        </Button>
      );
  }
};

export default UiButton;
