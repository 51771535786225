import { Order } from "src/core/models/order_model";

export type OrderState = {
  loading: boolean | false;
  adding: boolean | false;
  updating: boolean | false;
  deleting: boolean | false;
  hasMore: boolean;
  data: Order[] | [];
  dataAdmin: Order[];
  code?: string;
  message?: string;
};

export const OrderInitialState: OrderState = {
  loading: false,
  adding: false,
  updating: false,
  deleting: false,
  hasMore: false,
  data: [],
  dataAdmin: [],
};
