import axios from "axios";
import { ResponseRequestRecharge, ResponseRequestRecharges } from "../models/response_model";
import { Recharge } from "../models/request_model";
import { RequestRecharge } from "../interface/request_interface";

class RequestService {
  async getRequests(filter?: any): Promise<ResponseRequestRecharges> {
    let result: ResponseRequestRecharges = {};

    await axios
      .get("request_recharges", {
        params: filter,
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }
  
  async getRequestsHistorial(historialId?: string): Promise<ResponseRequestRecharges> {
    let result: ResponseRequestRecharges = {};

    await axios
      .get(`historial/request_recharges/${historialId}`)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getRequest(id: string) {
    let result: ResponseRequestRecharge = {};

    await axios
      .get(`request_recharge/${id}`)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async addRequest(data: RequestRecharge) {
    let result: ResponseRequestRecharges = {};

    await axios
      .post("request_recharge", data)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        console.log(err);
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async updateRequest(id?: string, data?: Recharge) {
    let result: ResponseRequestRecharge = {};

    await axios
      .put(`request_recharge/${id}`, data)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async deleteRequest(id?: string) {
    let result: ResponseRequestRecharge = {};

    await axios
      .delete(`request_recharge/${id}`)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }
}

export default RequestService;
