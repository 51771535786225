import Typography from "@mui/material/Typography";

type TitleProps = {
  text?: string;
};

export const Title = (props: TitleProps) => {
  const { text } = props;
  return (
    <Typography variant="h3" component="h3" gutterBottom>
      {text}
    </Typography>
  );
};

type SubtitleProps = {
  text?: string;
};

export const SubTitle = (props: SubtitleProps) => {
  const { text } = props;

  return <Typography variant="subtitle2">{text}</Typography>;
};

type BodyProps = {
  text?: string;
};

export const TextBody = (props: BodyProps) => {
  const { text } = props;

  return <Typography variant="body1">{text}</Typography>;
};

