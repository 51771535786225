
import { Grid } from "@mui/material";
import UiButton from "src/components/Buttons";
import { UserContentProps } from "src/core/types/content_type";
import Container from "src/components/Container";
import { TextLabel } from "src/components/Label";

const UserContent = (props: UserContentProps) => {
  const { setOpen, data } = props;

  return (
    <Container
      sx={{
        padding: 0,
      }}
    >
      <Grid container direction={"column"} spacing={2}>
        <Grid margin={0} item xs={12}>
          <TextLabel label="Nombre" text={data?.name} />
        </Grid>
        <Grid margin={0} item>
          <TextLabel label="NIF" text={data?.nif} />
        </Grid>
        <Grid margin={0} item>
          <TextLabel label="Dirección" text={data?.address} />
        </Grid>
        <Grid margin={0} item>
          <TextLabel label="Correo electrónico" text={data?.email} />
        </Grid>
        <Grid margin={0} item>
          <TextLabel label="Numero" text={data?.phone} />
        </Grid>
      </Grid>

      <Grid container paddingTop={3} spacing={2}>
        <UiButton
          variant="cancel"
          text="Cerrar"
          fullWidth
          onClick={() => setOpen(false)}
        />
      </Grid>
    </Container>
  );
};

export default UserContent;
