import { useState } from "react";
import { MuiForm } from "react-mui-form-validator";
import { Grid, Switch } from "@mui/material";
import { UiTextField } from "src/components/TextField";
import { useSnackbar } from "src/core/contexts/SnackbarProvider";
import UiButton from "src/components/Buttons";
import { CreditContentProps } from "src/core/types/content_type";
import { useCreditBloc } from "src/core/provider/Provider";
import { Credit } from "src/core/models/credit_model";
import UiLoadingButton from "src/components/LoadingButtons";
import { BlocBuilder } from "react-stream-bloc";
import { CreditState } from "src/core/bloc/credit/credit_state";
import { useTranslation } from "react-i18next";
import { TextLabel } from "src/components/Label";

const CreditContent = (props: CreditContentProps) => {
  const { setOpen, data } = props;
  const bloc = useCreditBloc();
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const [name, setName] = useState<string | undefined>(data?.name);
  const [amount, setAmount] = useState<number | undefined>(data?.amount);
  const [price, setPrice] = useState<number | undefined>(data?.price);
  const [checked, setChecked] = useState<boolean>(true);

  const handleAddUpdateCredit = async () => {
    const credit: Credit = {
      id: data?.id,
      name: name ?? "",
      amount: Number(amount),
      price: price != undefined ? Number(price) : 0,
      status: checked ? "enabled" : "disabled",
    };

    if (data) {
      const res = await bloc.updateCredit(credit);
      if (res) {
        if (res !== "success") {
          snackbar.error(t(`error.${res}`));
        }
        setOpen(false);
      }
    } else {
      const res = await bloc.addCredit(credit);
      if (res) {
        if (res !== "success") {
          snackbar.error(t(`error.${res}`));
        }
        setOpen(false);
      }
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <BlocBuilder
      bloc={bloc}
      builder={(state: CreditState) => (
        <MuiForm
          onSubmit={handleAddUpdateCredit}
          style={{
            maxWidth: 350,
            padding: 0
          }}
        >
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <UiTextField
                value={name}
                label="Nombre"
                placeholder="Nombre"
                fullWidth
                onChange={(e: any) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <UiTextField
                value={amount}
                label="Cantidad"
                disabled={data !== undefined}
                placeholder="0"
                onChange={(e: any) => setAmount(e.target.value)}
                required
                errorMessage="Ingrese cantidad"
              />
            </Grid>
            <Grid item xs={6}>
              <UiTextField
                value={price}
                label="Precio"
                placeholder="0"
                onChange={(e: any) => setPrice(e.target.value)}
                required
                errorMessage="Ingrese precio"
              />
            </Grid>
          </Grid>
          <TextLabel
            label={checked ? "Desactivar crédito" : "Activar crédito"}
            sx={{ mt: 2 }}
          >
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          </TextLabel>

          <Grid container paddingTop={3} spacing={2}>
            <Grid item xs={6} textAlign="center">
              <UiButton
                fullWidth
                variant="outlined"
                text="Cancelar"
                onClick={() => setOpen(false)}
              />
            </Grid>
            <Grid item xs={6} textAlign="center">
              <UiLoadingButton
                fullWidth
                loading={data !== undefined ? state.updating : state.adding}
                text={data ? "Actualizar" : "Agregar"}
                type="submit"
              />
            </Grid>
          </Grid>
        </MuiForm>
      )}
    />
  );
};

export default CreditContent;
