import Container from "src/components/Container";
import { Box, Chip } from "@mui/material";
import UiButton from "src/components/Buttons";
import { useState, useEffect } from "react";
import { useCreditBloc, useMyCreditBloc } from "src/core/provider/Provider";
import MyCreditList from "./components/MyCreditList";
import BuyCreditList from "./components/BuyCreditList";
import { TextLabel } from "src/components/Label";
import { BlocBuilder } from "react-stream-bloc";
import { MyCreditState } from "src/core/bloc/myCredit/my_credit_state";
import { AuthState } from "src/core/bloc/auth/auth_state";
import useWindowDimensions from "src/core/utils/dimensions";

interface MyCreditContentProps {
  state: AuthState;
}
const MyCreditContent = ({ state }: MyCreditContentProps) => {
  const bloc = useCreditBloc();
  const myCredit = useMyCreditBloc();
  const { width, height } = useWindowDimensions();
  const [buy, setBuy] = useState<boolean>(false);

  const initData = () => {
    if (!myCredit.credit) {
      myCredit.getMyCredit();
    }
    if (bloc.credits.length === 0) {
      bloc.getCredits();
    }
  };

  const handleBuy = () => {
    if (buy) {
      setBuy(false);
    } else {
      setBuy(true);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  const Header = () => {
    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="end"
        justifyItems="center"
      >
        <UiButton
          text="Comprar"
          onClick={handleBuy}
          disabled={state.user?.status !== "active" ? true : false}
        />
        <Chip
          sx={{
            ml: 1.5,
            height: 38,
          }}
          label={
            <Box display="flex" flexDirection="row" alignItems="center">
              <TextLabel label="Creditos:" sx={{ ml: 1, mr: 1 }} />
              <BlocBuilder
                bloc={myCredit}
                builder={(state: MyCreditState) => (
                  <TextLabel text={state.data?.credits} sx={{ mr: 1 }} />
                )}
              />
            </Box>
          }
          variant="outlined"
        />
      </Box>
    );
  };

  return (
    <Container
      helmetTitle="Creditos"
      title={buy ? "Comprar creditos" : "Mis creditos"}
      toolbar={width > 630 ? <Header /> : undefined}
    >
      {width < 630 ? <Header /> : undefined}
      {buy ? <BuyCreditList /> : undefined}
      <MyCreditList />
    </Container>
  );
};

export default MyCreditContent;
