import axios from "axios";
import { ResponseHistorialAll, ResponseHistorial } from "../models/response_model";

class HistoryService {
  async getHistorialAll(filter?: any): Promise<ResponseHistorialAll> {
    let result: ResponseHistorialAll = {};

    await axios
      .get("historial/all", {
        params: filter,
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getHistorial(filter?: any): Promise<ResponseHistorialAll> {
    let result: ResponseHistorialAll = {};

    await axios
      .get("historial", {
        params: filter,
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getHistory(id?: string): Promise<ResponseHistorial> {
    let result: ResponseHistorial = {};

    await axios
      .get(`historial/${id}`)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }
}

export default HistoryService;
