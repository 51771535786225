import axios from "axios";
import { ResponseLine, ResponseLines } from "../models/response_model";
import { Line } from "../models/line_model";

class LineService {
  async getLines(filter?: any): Promise<ResponseLines> {
    let result: ResponseLines = {};

    await axios
      .get("lines", {
        params: filter,
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async searchLines(query: string) {
    let result: ResponseLines = {};

    await axios
      .get(`lines/search`, {
        params: {
          query: query,
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getLine(id: string) {
    let result: ResponseLine = {};

    await axios
      .get(`line/${id}`)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async addLine(user_line_id: string, data: string[]) {
    let result: ResponseLines = {};

    await axios
      .post(`line/${user_line_id}`, data)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        console.log(err);
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async updateLine(id?: string, data?: Line) {
    let result: ResponseLine = {};

    await axios
      .put(`line/${id}`, data)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async resetLines() {
    let result: ResponseLines = {};

    await axios
      .put(`reset/lines`)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async deleteLine(id?: string) {
    let result: ResponseLine = {};

    await axios
      .delete(`line/${id}`)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }
}

export default LineService;
