import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import GlobalStyles from "@mui/material/GlobalStyles";
import useTheme from "@mui/material/styles/useTheme";
import React, { useState } from "react";
import Logo from "./Logo";

type BoxedLayoutProps = {
  children: React.ReactNode;
};

const BoxedLayout = ({ children }: BoxedLayoutProps) => {
  const theme = useTheme();
  const [settingsOpen, setSettingsOpen] = useState(false);

  const handleSettingsToggle = () => {
    setSettingsOpen(!settingsOpen);
  };

  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ body: { backgroundColor: theme.palette.background.paper } }}
      />

      <Container
        component="main"
        maxWidth="xs"
        sx={{  alignItems: "center", justifyContent: "center" }}
      >
        <Box
          
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: '100vh'
          }}
        >
          <Logo sx={{ mb: 1 }} />
          {children}
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default BoxedLayout;
