import * as React from "react";
import { UserListProps } from "src/core/types/list_type";
import Box from "@mui/material/Box";

import { User } from "src/core/models/user_model";
import { CircularProgress } from "@mui/material";
import Empty from "src/core/components/Empty";
import ContainerContent from "src/components/Container/Content";
import { BlocBuilder } from "react-stream-bloc";
import { UserState } from "src/core/bloc/user/user_state";
import InfiniteScroll from "react-infinite-scroll-component";
import ItemContent from "./Item";

const UserList = (props: UserListProps) => {
  const { bloc, onEdit, onAddCredit } = props;

  return (
    <BlocBuilder
      bloc={bloc}
      builder={(state: UserState) => {
        return (
          <ContainerContent loading={state.loading}>
            {state.searching ? (
              <Box
                alignItems="center"
                justifyItems="center"
                textAlign="center"
                marginTop={1}
              >
                <CircularProgress size={27} />
              </Box>
            ) : undefined}
            {state.filtered.length > 0 ? (
              state.filtered.map((item: User) => {
                return (
                  <ItemContent
                    key={item.uid}
                    bloc={bloc}
                    onAddCredit={onAddCredit}
                    onEdit={onEdit}
                    state={state}
                    user={item}
                  />
                );
              })
            ) : state.data.length !== 0 ? (
              <InfiniteScroll
                dataLength={state.data.length}
                next={() => bloc.getNextUsers()}
                hasMore={state.hasMore ? false : true} // Replace with a condition based on your data source
                loader={<></>}
              >
                <Box
                  marginBottom={2}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {state.data.map((item: User) => {
                    return (
                      <ItemContent
                        key={item.uid}
                        bloc={bloc}
                        onAddCredit={onAddCredit}
                        onEdit={onEdit}
                        state={state}
                        user={item}
                      />
                    );
                  })}
                  {state.hasMore ? (
                    <Box
                      alignItems="center"
                      justifyItems="center"
                      textAlign="center"
                      marginTop={1}
                    >
                      <CircularProgress size={27} />
                    </Box>
                  ) : undefined}
                </Box>
              </InfiniteScroll>
            ) : (
              <Empty title="Aún no tienes operadores" />
            )}
          </ContainerContent>
        );
      }}
    />
  );
};

export default UserList;
