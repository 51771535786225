import CreditService from "src/core/services/credit_service";
import { Bloc } from "react-stream-bloc";
import { CreditInitialState, CreditState } from "./credit_state";
import { Credit } from "src/core/models/credit_model";

export class CreditBloc extends Bloc<CreditState> {
  credits: Credit[] = [];

  constructor(private service: CreditService) {
    super(CreditInitialState);
    this.getCredits();
  }

  async getCredits() {
    this.changeState({ ...this.state, loading: true });
    const response = await this.service.getCredits();

    if (response.code === "success") {
      this.changeState(this.mapToLoadedState(response.data ?? []));
    } else {
      this.changeState(this.mapToLoadedState(this.credits));
    }
  }

  async addCredit(data: Credit): Promise<string | undefined> {
    this.changeState({ ...this.state, adding: true });
    const response = await this.service.addCredit(data);

    if (response.code === "success") {
      const credit = response.data;
      if (credit) {
        const newCredits = [...this.credits, credit];

        this.changeState(this.mapToLoadedState(newCredits));
      } else {
        this.changeState({ ...this.state, adding: false });
      }
      return response.code;
    } else {
      this.changeState(this.mapToLoadedState(this.credits));
      return response.code;
    }
  }

  async updateCredit(data: Credit): Promise<string | undefined> {
    this.changeState({ ...this.state, updating: true });
    const response = await this.service.updateCredit(data.id, data);
    if (response.code === "success") {
      const credit = response.data;
      const newCredits = this.credits.map((oldCredit) => {
        if (oldCredit.id === credit?.id) {
          return {
            ...oldCredit,
            name: credit?.name ?? "",
            amount: credit?.amount,
            price: credit?.price ?? 0,
            tax: credit?.tax,
            updated_at: credit?.updated_at,
          };
        } else {
          return oldCredit;
        }
      });
      this.changeState(this.mapToLoadedState(newCredits));
      return response.code;
    } else {
      this.changeState(this.mapToLoadedState(this.credits));
      return response.code;
    }
  }

  async updateStatusCredit(id?: string, status?: string): Promise<string | undefined> {
    this.changeState({ ...this.state, updating: true });
    const response = await this.service.updateStatusCredit(id, status);
    if (response.code === "success") {
      const credit = response.data;
      const newCredits = this.credits.map((oldCredit) => {
        if (oldCredit.id === id) {
          return {
            ...oldCredit,
            updated_at: credit?.updated_at,
            status: credit?.status,
          };
        } else {
          return oldCredit;
        }
      });
      this.changeState(this.mapToLoadedState(newCredits));
      return response.code;
    } else {
      this.changeState(this.mapToLoadedState(this.credits));
      return response.code;
    }
  }

  async deleteCredit(id: string) {
    this.changeState({ ...this.state, deleting: true });
    const res = await this.service.deleteCredit(id);
    if (res.code === "success") {
      const newUsers = this.credits.filter((i) => i.id !== id);
      this.changeState(this.mapToLoadedState(newUsers));
    } else {
      this.changeState(this.mapToLoadedState(this.credits));
    }
  }

  mapToLoadedState(data: Credit[]): CreditState {
    this.credits = data;
    return {
      loading: false,
      adding: false,
      deleting: false,
      updating: false,
      data: data,
    };
  }
}
