import { useState } from "react";
import { MuiForm } from "react-mui-form-validator";
import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Typography,
} from "@mui/material";
import { UiTextField } from "src/components/TextField";
import { useSnackbar } from "src/core/contexts/SnackbarProvider";
import UiButton from "src/components/Buttons";
import { RequestContentProps } from "src/core/types/content_type";
import {
  useAuthBloc,
  useHistoryBloc,
  useMyCreditBloc,
  useRequestBloc,
} from "src/core/provider/Provider";
import { useTranslation } from "react-i18next";
import { BlocBuilder } from "react-stream-bloc";
import { RequestState } from "src/core/bloc/request/request_state";
import UiLoadingButton from "src/components/LoadingButtons";
import useWindowDimensions from "src/core/utils/dimensions";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/RemoveRounded";
import RemoveIcon from "@mui/icons-material/DeleteRounded";
import { TextLabel } from "src/components/Label";
import { Stack } from "@mui/system";
import { Numbers, RequestRecharge } from "src/core/interface/request_interface";
import TextSnippetIcon from "@mui/icons-material/TextSnippetRounded";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import { useStyles } from "../styles/style";
import short from "short-uuid";

const credits: number[] = [1, 2, 3];

const RequestContent = (props: RequestContentProps) => {
  const { setOpen, data, saved } = props;
  const bloc = useRequestBloc();
  const auth = useAuthBloc();
  const historialBloc = useHistoryBloc();
  const myCredit = useMyCreditBloc();
  const snackbar = useSnackbar();
  const classes = useStyles();
  const { width, height } = useWindowDimensions();
  const { t } = useTranslation();
  const [historialId] = useState<string>(short.generate());

  const [credit, setCredit] = useState<number>(3);
  const [numbers, setNumbers] = useState<Numbers[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop: (files) => onDrop(files),
      accept: { "text/csv": [".csv"] },
    });

  const handleAdd = async () => {
    if (numbers.length > 0) {
      const total_credits = numbers
        .map((item) => item.amount_credit)
        .reduce((a, b) => a + b);
      const data: RequestRecharge = {
        history_id: historialId,
        numbers: numbers,
        total_credits: total_credits,
        credit_per_number: credits.length,
      };
      const res = await bloc.addRequest(data);

      if (res === "success") {
        snackbar.success("Su solicitud de carga fue exitosa.");
        myCredit.getMyCredit();
        historialBloc.getHistorial();
      } else {
        snackbar.error(t(`error.${res}`));
      }
      setOpen(false);
    }
  };

  const addTextField = () => {
    setErrorMessage(undefined);
    const numberId = short.generate();
    const date = new Date();

    const newItems = [
      ...numbers,
      {
        id: numberId,
        number: "",
        user_id: auth.user?.uid,
        amount_credit: Number(credit),
        bytes: 0,
        error_ids: 0,
        history_id: historialId,
        not_used_credits: 0,
        used_credits: 0,
        created_at: { $date: date },
        ids: [1, 1, 1],
      },
    ];

    setNumbers(newItems);
  };

  const onChangeNumber = (v: any, id: string) => {
    const updatedItems = numbers.map((item) => {
      if (item.id === id) {
        return { ...item, number: v.target.value };
      }
      return item;
    });

    setNumbers(updatedItems);
  };

  const onChangeGBNumber = (v: any, index: number) => {
    const date = new Date();
    const newNumbers: Numbers[] = numbers.map((c, i) => {
      if (i === index) {
        return {
          ...numbers,
          id: c.id,
          number: c.number,
          amount_credit:
            v.target.value != undefined ? Number(v.target.value) : 0,
          user_id: c.user_id,
          bytes: 0,
          error_ids: 0,
          history_id: historialId,
          not_used_credits: 0,
          used_credits: 0,
          created_at: date,
          ids: [1, 1, 1],
        };
      } else {
        return c;
      }
    });
    setNumbers(newNumbers);
  };

  const onDrop = async (files: File[]) => {
    setErrorMessage(undefined);
    const file = files[0];

    if (!file)
      return setErrorMessage(
        "¡Formato de archivo incorrecto!. Por favor seleccione un archivo .CSV"
      );

    try {
      // Initialize a reader which allows user
      // to read any file or blob.
      const reader = new FileReader();
      const date = new Date();

      // Event listener on reader when the file
      // loads, we parse it and set the data.
      reader.onload = async ({ target }: any) => {
        const csv = Papa.parse(target.result, {
          header: true,
          delimiter: ",",
        });
        const parsedData: any[] = csv?.data;
        let importNumbers: Numbers[] = [];
        await Promise.all(
          parsedData.map((v) => {
            const numberId = short.generate();
            const rows = Object.keys(v);
            const columns = Object.values(v);
            const res: any[] = rows.reduce((acc: any, e, i) => {
              return [...acc, [[e], columns[i]]];
            }, []);

            const numberInsert = res[0] ? res[0][1] : undefined;
            //const amount_credit = res[1] ? res[1][1] : undefined;

            if (numberInsert) {
              const data = numbers.find((item) => item.number === numberInsert);
              if (data) {
                setNumbers(numbers.filter((v, i) => v.number !== data.number));
              }
              if (numberInsert.length > 6)
                importNumbers.push({
                  id: numberId,
                  amount_credit: Number(credit),
                  number: numberInsert,
                  user_id: auth.user?.uid,
                  bytes: 0,
                  error_ids: 0,
                  history_id: historialId,
                  not_used_credits: 0,
                  used_credits: 0,
                  created_at: { $date: date },
                  ids: [1, 1, 1],
                });
            }
          })
        );
        setNumbers(importNumbers);
      };
      reader.readAsText(file);
    } catch (err) {
      setErrorMessage("¡Formato de archivo incorrecto!");
    }
  };

  return (
    <BlocBuilder
      bloc={bloc}
      builder={(state: RequestState) => (
        <>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            justifyItems="center"
            style={{
              width: "100%",
            }}
            paddingBottom={1}
          >
            <Typography variant="h5">
              {data ? "Detalles de solicitud" : "Solicitar cargas"}
            </Typography>
            <Stack direction="row" alignItems="end">
              {numbers.length > 0 ? (
                <IconButton
                  color="error"
                  aria-label="Eliminar lineas"
                  disabled={state.adding}
                  onClick={() => setNumbers([])}
                  style={{
                    marginRight: 5,
                  }}
                >
                  <RemoveIcon />
                </IconButton>
              ) : undefined}
              <IconButton
                color="primary"
                aria-label="Agregar numeros"
                disabled={state.adding}
                onClick={addTextField}
              >
                <AddIcon />
              </IconButton>
            </Stack>
          </Box>
          <Box
            alignItems={"center"}
            sx={{
              width: "100%",
            }}
            textAlign={"center"}
          >
            {state.adding ? <LinearProgress sx={{ height: 3 }} /> : undefined}
          </Box>

          <MuiForm
            onSubmit={handleAdd}
            style={{
              width:
                width > 500
                  ? width > 700
                    ? width > 800
                      ? 700
                      : 450
                    : 400
                  : undefined,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxHeight: height - 250,
                overflow: "hidden",
                overflowY: "scroll",
              }}
            >
              {errorMessage ? <TextLabel error={errorMessage} /> : undefined}

              <Grid container spacing={1} alignItems="center" paddingTop={1}>
                {numbers.map((v, index) => {
                  return (
                    <Grid item key={index} xs={12} sm={6} md={4}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignContent="center"
                        alignItems="center"
                      >
                        <TextLabel label="+34" sx={{ mr: 1 }} />
                        <UiTextField
                          value={v.number}
                          placeholder="123456789"
                          number
                          fullWidth
                          onChange={(e: any) => onChangeNumber(e, v.id)}
                          validators={[
                            { validator: "required" },
                            { validator: "minStringLength", min: 6 },
                            { validator: "maxStringLength", max: 10 },
                          ]}
                          disabled={state.adding}
                          errorMessage={[
                            "Ingrese numero",
                            "Número invalido!",
                            "Número invalido!",
                          ]}
                        />
                        {/*<UiTextField
                            value={v.amount_credit}
                            placeholder="1"
                            number
                            width={20}
                            onChange={(e: any) => onChangeGBNumber(e, index)}
                            validators={[
                              { validator: "required" },
                              { validator: "minNumber", min: 1 },
                              { validator: "maxNumber", max: 100 },
                            ]}
                            errorMessage={[
                              "GB invalido!",
                              "GB invalido!",
                              "GB invalido!",
                            ]}
                            sx={{
                              marginLeft: 0.5,
                            }}
                          />*/}
                        <IconButton
                          color="error"
                          aria-label="Eliminar numero"
                          disabled={state.adding}
                          onClick={() =>
                            setNumbers(numbers.filter((v, i) => i !== index))
                          }
                          style={{
                            height: 35,
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
              {!state.adding ? (
                <>
                  <div
                    className={classes.drag}
                    {...getRootProps({ isFocused, isDragAccept, isDragReject })}
                    style={{
                      borderColor: `${
                        isFocused
                          ? "#2196f3"
                          : isDragAccept
                          ? "#00e676"
                          : isDragReject
                          ? "#ff1744"
                          : "#eeeeee"
                      }`,
                    }}
                  >
                    <input {...getInputProps()} />
                    <TextLabel label="Arrastre y suelte su archivo .csv o haga clic para seleccionar" />
                  </div>
                  <Box display="flex" flexDirection="row" sx={{ mt: 1 }}>
                    <Link
                      href="/csv/Formato - Solicitud de Cargas.csv"
                      underline="hover"
                    >
                      Descargar formato
                    </Link>
                    <TextSnippetIcon color="primary" />
                  </Box>
                </>
              ) : undefined}
            </Box>
            {/*<Box sx={{ mt: 2 }} display="flex" flexDirection="column">
                  <UiLabel text="Seleccione creditos" />
                  <Grid container spacing={1}>
                    {credits.map((v, index) => (
                      <Grid key={v} item xs={6} sm={3}>
                        <UiButton
                          variant={v === credit ? "contained" : "outlined"}
                          fullWidth
                          sx={{
                            padding: 0,
                            paddingLeft: 2.5,
                            paddingRight: 2.5,
                            paddingTop: 1.2,
                            paddingBottom: 1.2,
                          }}
                          text={`${v} = ${v * 10}GB`}
                          onClick={() => setCredit(v)}
                        />
                      </Grid>
                        ))}
                    <Grid item xs={6} sm={3}>
                      <Stack direction="row" alignItems="center">
                        <UiTextField
                          key={"add-credits"}
                          value={credit}
                          placeholder="123456789"
                          number
                          required
                          onChange={(e: any) => setCredit(e.target.value)}
                          validators={[{ validator: "maxNumber", max: 20 }]}
                          width={18}
                          errorMessage={["Creditos invalidos"]}
                        />
                        <TextLabel label={` ${credit * 10}GB`} sx={{ ml: 1 }} />
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>*/}

            <Grid container paddingTop={3} spacing={2}>
              <Grid item xs={6} textAlign="center">
                <UiButton
                  fullWidth
                  disabled={state.adding}
                  variant="outlined"
                  text="Cancelar"
                  onClick={() => setOpen(false)}
                />
              </Grid>
              <Grid item xs={6} textAlign="center">
                <UiLoadingButton
                  loading={state.adding}
                  fullWidth
                  disabled={numbers.length === 0 ? true : undefined}
                  text={"Solicitar"}
                  type="submit"
                />
              </Grid>
            </Grid>
          </MuiForm>
        </>
      )}
    />
  );
};

export default RequestContent;
