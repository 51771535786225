import * as React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { MuiPhoneNumber, MuiTextField } from "react-mui-form-validator";
import {
  UiTextFieldPhoneProps,
  UiTextFieldProps,
} from "src/core/types/textfield_type";
import { useStyles } from "./styles";

export const UiTextField = (props: UiTextFieldProps) => {
  const {
    id,
    fullWidth,
    children,
    label,
    placeholder,
    autoComplete,
    value,
    onChange,
    required,
    select,
    errorMessage,
    number,
    type,
    disabled,
    validators,
    sx,
    textfieldsx,
    multiline,
    height,
    width,
  } = props;
  const classes = useStyles();

  const handleChange = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      onChange(e);
    }
  };

  return (
    <Box sx={sx}>
      <Stack direction="column">
        {label ? (
          <Typography sx={{ fontSize: 14 }} color="text.label">
            {label}
            {required ? " *" : ""}
          </Typography>
        ) : undefined}

        <MuiTextField
          placeholder={placeholder}
          className={classes.texfield}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          type={type}
          fullWidth={fullWidth}
          disabled={disabled}
          select={select}
          autoComplete={autoComplete ?? "off"}
          value={value}
          onChange={number ? handleChange : onChange}
          multiline={multiline}
          validators={
            validators !== undefined
              ? validators
              : required
              ? [{ validator: "required" }]
              : []
          }
          errorMessages={errorMessage ?? []}
          inputProps={{
            className: classes.input,
            style: {
              height: height !== undefined ? height : 12,
              width: width !== undefined ? width : undefined,
            },
          }}
          sx={textfieldsx}
        >
          {children}
        </MuiTextField>
      </Stack>
    </Box>
  );
};

export const UiTextFieldPhone = (props: UiTextFieldPhoneProps) => {
  const {
    id,
    fullWidth,
    label,
    placeholder,
    autoComplete,
    value,
    onChange,
    required,
    errorMessage,
    number,
    type,
    disabled,
    validators,
    sx,
    height,
  } = props;

  const handleChange = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      onChange(e);
    }
  };

  return (
    <Box sx={sx}>
      <Stack direction="column">
        <div className="input-label">
          {label}
          {required ? " *" : ""}
        </div>
        <MuiPhoneNumber
          placeholder={placeholder}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          type={type}
          fullWidth={fullWidth}
          disabled={disabled}
          autoComplete={autoComplete ?? "off"}
          value={value}
          onChange={number ? handleChange : onChange}
          validators={
            validators !== undefined
              ? validators
              : required
              ? [{ validator: "required" }]
              : []
          }
          errorMessages={errorMessage ?? []}
          inputProps={{
            style: {
              height: height !== undefined ? height : 12,
            },
          }}
        />
      </Stack>
    </Box>
  );
};
