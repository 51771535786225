import service from "src/core/services/user_line_service";
import { Bloc } from "react-stream-bloc";
import { UserLineInitialState, UserLineState } from "./user_line_state";
import { UserLine } from "src/core/models/line_model";

export class UserLineBloc extends Bloc<UserLineState> {
  lines: UserLine[] = [];

  constructor(private service: service) {
    super(UserLineInitialState);
    this.getLines();
  }

  async getLines() {
    this.changeState({ ...this.state, loading: true });
    const response = await this.service.getUserLines();

    if (response.code === "success") {
      this.changeState(this.mapToLoadedState(response.data ?? []));
    } else {
      this.changeState(this.mapToLoadedState(this.lines));
    }
  }

  async addUserLine(data?: UserLine): Promise<string | undefined> {
    this.changeState({ ...this.state, adding: true });
    const response = await this.service.addUserLine(data);

    if (response.code === "success") {
      const line = response.data;
      if (line) {
        const newCredits = [line].concat(this.lines);

        this.changeState(this.mapToLoadedState(newCredits));
      } else {
        this.changeState({ ...this.state, adding: false });
      }
      return response.code;
    } else {
      this.changeState({ ...this.state, adding: false });
      return response.code;
    }
  }

  async updateLine(data: UserLine): Promise<string | undefined> {
    this.changeState({ ...this.state, updating: true });
    const response = await this.service.updateUserLine(data.id, data);
    if (response.code === "success") {
      const line = response.data;
      if (line) {
        const newLines = this.lines.map((oldLine) => {
          if (oldLine.id === line?.id) {
            return {
              ...oldLine,
              bytes: line.bytes,
              password: line.password,
              username: line.username,
              updated_at: line.updated_at,
            };
          } else {
            return oldLine;
          }
        });
        this.changeState(this.mapToLoadedState(newLines));
      }
      return response.code;
    } else {
      this.changeState(this.mapToLoadedState(this.lines));
      return response.code;
    }
  }

  async deleteLine(id: string): Promise<string | undefined> {
    this.changeState({ ...this.state, deleting: true });
    const res = await this.service.deleteUserLine(id);
    if (res.code === "success") {
      const newUsers = this.lines.filter((i) => i.id !== id);
      this.changeState(this.mapToLoadedState(newUsers));
    } else {
      this.changeState(this.mapToLoadedState(this.lines));
    }
    return res.code;
  }

  mapToLoadedState(data: UserLine[]): UserLineState {
    this.lines = data;
    return {
      loading: false,
      adding: false,
      deleting: false,
      updating: false,
      data: data,
    };
  }
}
