import { alpha } from "@mui/system";
import { Theme, makeStyles } from "mui-styles";

export const useStyles = makeStyles((theme: Theme) => ({
  drag: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: "2px",
    borderRadius: "5px",
    marginTop: 10,
        borderStyle: "dashed",
    backgroundColor: theme.palette.mode === 'light' ? "#fafafa" : alpha("#fafafa", 0.15),
    color: "#bdbdbd",
    outline: "none",
    transition: "border 0.24s ease-in-out",
  },
}));
