import Container from "src/components/Container";
import { useState, useEffect, useMemo } from "react";
import { UiDialog } from "src/components/Dialog";
import Content from "./components/Content";
import { Container as ContainerDialog, debounce } from "@mui/material";
import { User } from "src/core/models/user_model";
import { useUserBloc } from "src/core/provider/Provider";
import UserList from "src/admin/pages/user/components/UserList";
import AddCreditUser from "./components/AddCredit";

const UserContent = () => {
  const bloc = useUserBloc();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openDialogAdd, setOpenDialogAdd] = useState<boolean>(false);
  const [user, setUser] = useState<User>();
  const [search, setSearch] = useState<string>("");
  const [debouncedInputValue, setDebouncedInputValue] = useState<string>("");

  const initData = () => {
    if (bloc.users.length === 0) {
      bloc.getUsers();
    }
  };

  const handleEdit = (v: User) => {
    setUser(v);
    setOpenDialog(true);
  };

  const handleAddCredit = (v?: User) => {
    setUser(v);
    setOpenDialogAdd(true);
  };

  const handleChangeSearch = (v: any) => {
    setSearch(v.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      bloc.searchUsers(search);
    }, 700);
    return () => clearTimeout(timeoutId);
  }, [search, 700]);

  useEffect(() => {
    initData();
  }, []);

  return (
    <Container
      helmetTitle="Operadores"
      title="Operadores"
      search
      placeholderSearch="Buscar operador por email"
      searchOnChange={handleChangeSearch}
    >
      <UserList bloc={bloc} onEdit={handleEdit} onAddCredit={handleAddCredit} />
      <UiDialog open={openDialog} title={"Detalles del operador"} maxWidth="lg">
        <ContainerDialog className="dialog-container">
          <Content setOpen={(v: boolean) => setOpenDialog(v)} data={user} />
        </ContainerDialog>
      </UiDialog>
      <UiDialog open={openDialogAdd} title={"Agregar créditos"} maxWidth="lg">
        <AddCreditUser
          setOpen={(v: boolean) => setOpenDialogAdd(v)}
          data={user}
        />
      </UiDialog>
    </Container>
  );
};

export default UserContent;
