import Container from "src/components/Container";
import { BlocBuilder } from "react-stream-bloc";
import { Box } from "@mui/material";
import UiButton from "src/components/Buttons";
import { useState, useEffect } from "react";
import { UiDialog } from "src/components/Dialog";
import Content from "./components/Content";
import { useRequestBloc } from "src/core/provider/Provider";
import { Recharge } from "src/core/models/request_model";
import { RequestState } from "src/core/bloc/request/request_state";
import RequestList from "./components/List";

const RequestContent = () => {
  const bloc = useRequestBloc();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [recharge, setRecharge] = useState<Recharge>();

  const initData = () => {
    if (bloc.request_recharges.length === 0) {
      bloc.getRequests();
    }
  };

  const handleAdd = () => {
    setRecharge(undefined);
    setOpenDialog(true);
  };

  const handleEdit = (v: Recharge) => {
    setRecharge(v);
    setOpenDialog(true);
  };

  const onSaved = (v?: Recharge) => {
    if (v) {
      setTimeout(() => {
        bloc.getRequestsHistory(v.history_id);
      }, 5000);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Container helmetTitle="Solicitar carga" title="Solicitudes de cargas">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="end"
        justifyItems="center"
        style={{
          width: "100%",
          marginTop: 10,
        }}
      >
        <UiButton text="Solicitar cargas" onClick={handleAdd} />
      </Box>
      <RequestList bloc={bloc} onEdit={handleEdit} />
      <UiDialog open={openDialog} maxWidth="lg">
        <Content
          setOpen={(v: boolean) => setOpenDialog(v)}
          data={recharge}
          saved={onSaved}
        />
      </UiDialog>
    </Container>
  );
};

export default RequestContent;
