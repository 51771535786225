import * as React from "react";
import { LoadingButtonProps } from "src/core/types/button_type";
import { useStyles } from "./styles";
import LoadingButton from "@mui/lab/LoadingButton";

const UiLoadingButton = (props: LoadingButtonProps) => {
  const {
    type,
    text,
    variant,
    onClick,
    fullWidth,
    loading = false,
    disabled,
    sx,
  } = props;
  const classes = useStyles();

  switch (variant) {
    case "outlined":
      return (
        <LoadingButton
          className={classes.outlined}
          type={type}
          variant="outlined"
          onClick={onClick}
          disabled={disabled !== undefined ? disabled : loading}
          loading={loading}
          style={{
            width: fullWidth ? "100%" : "auto",
            minWidth: fullWidth ? "auto" : 140,
          }}
          sx={sx}
        >
          {text}
        </LoadingButton>
      );
    case "contained":
      return (
        <LoadingButton
          type={type}
          variant="contained"
          className={classes.contained}
          onClick={onClick}
          disabled={disabled !== undefined ? disabled : loading}
          loading={loading}
          style={{
            width: fullWidth ? "100%" : "auto",
            minWidth: fullWidth ? "auto" : 140,
          }}
          sx={sx}
        >
          {text}
        </LoadingButton>
      );
    case "cancel":
      return (
        <LoadingButton
          type={type}
          variant="outlined"
          className={classes.cancel}
          onClick={onClick}
          disabled={disabled !== undefined ? disabled : loading}
          loading={loading}
          style={{
            width: fullWidth ? "100%" : "auto",
            minWidth: fullWidth ? "auto" : 140,
          }}
          sx={sx}
        >
          {text}
        </LoadingButton>
      );
    case "delete":
      return (
        <LoadingButton
          type={type}
          variant="contained"
          color="primary"
          className={classes.delete}
          onClick={onClick}
          disabled={disabled !== undefined ? disabled : loading}
          loading={loading}
          style={{
            width: fullWidth ? "100%" : "auto",
            minWidth: fullWidth ? "auto" : 140,
          }}
          sx={sx}
        >
          {text}
        </LoadingButton>
      );
    case "text":
      return (
        <LoadingButton
          type={type}
          variant="text"
          className={classes.outlined}
          onClick={onClick}
          disabled={disabled !== undefined ? disabled : loading}
          loading={loading}
          style={{
            width: fullWidth ? "100%" : "auto",
            minWidth: fullWidth ? "auto" : 140,
          }}
          sx={sx}
        >
          {text}
        </LoadingButton>
      );
    default:
      return (
        <LoadingButton
          type={type}
          variant="contained"
          className={classes.contained}
          onClick={onClick}
          disabled={disabled !== undefined ? disabled : loading}
          loading={loading}
          style={{
            width: fullWidth ? "100%" : "auto",
            minWidth: fullWidth ? "auto" : 140,
          }}
          sx={sx}
        >
          {text}
        </LoadingButton>
      );
  }
};

export default UiLoadingButton;
