import * as React from "react";
import { useState } from "react";
import { CreditListProps } from "src/core/types/list_type";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useCreditBloc } from "src/core/provider/Provider";
import { Credit } from "src/core/models/credit_model";
import { Card, Grid, MenuItem } from "@mui/material";
import { Stack } from "@mui/system";
import Empty from "src/core/components/Empty";
import OffIcon from "@mui/icons-material/ToggleOffRounded";
import OnIcon from "@mui/icons-material/ToggleOnRounded";
import ContainerContent from "src/components/Container/Content";
import { TextLabel } from "src/components/Label";

const CreditList = (props: CreditListProps) => {
  const { loading, data, onEdit } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [credit, setCredit] = useState<Credit>();

  const openActions = Boolean(anchorEl);
  const bloc = useCreditBloc();

  const handleOpenActions = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: Credit
  ) => {
    setCredit(item);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setAnchorEl(null);
    if (credit) onEdit(credit);
  };

  const handleStatus = async () => {
    setAnchorEl(null);
    if (credit)
      await bloc.updateStatusCredit(
        credit.id,
        credit.status === "enabled" ? "disabled" : "enabled"
      );
  };

  const handleDelete = () => {
    setAnchorEl(null);
    if (credit) bloc.deleteCredit(credit.id ?? "");
  };

  if (!loading) {
    if (data.length === 0) {
      return <Empty title="Aún no tienes creditos" />;
    }
  }

  return (
    <ContainerContent loading={loading}>
      <Box flexDirection="column">
        {data.map((item: Credit) => {
          return (
            <Card key={item.id} style={{ marginTop: 10 }}>
              <Stack direction="row">
                <Grid container direction="row" spacing={1} padding={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextLabel label="Nombre" text={item.name} />
                  </Grid>
                  <Grid item xs={4} sm={2} md={2} lg={2}>
                    <TextLabel label="Cantidad" text={item.amount} />
                  </Grid>
                  <Grid item xs={4} sm={2} md={2} lg={2}>
                    <TextLabel label="Precio" text={"€ " + item.price} />
                  </Grid>
                  <Grid item xs={4} sm={2} md={2} lg={2}>
                    <TextLabel label="Estado">
                      {item.status === "enabled" ? (
                        <OnIcon color="primary" />
                      ) : (
                        <OffIcon />
                      )}
                    </TextLabel>
                  </Grid>
                </Grid>
                <IconButton
                  id="user-row-menu-button"
                  aria-label="user actions"
                  aria-controls="user-row-menu"
                  aria-haspopup="true"
                  style={{
                    maxHeight: 45,
                    maxWidth: 45,
                  }}
                  aria-expanded={openActions ? "true" : "false"}
                  disabled={loading}
                  onClick={(v) => handleOpenActions(v, item)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="user-row-menu"
                  anchorEl={anchorEl}
                  aria-labelledby="user-row-menu-button"
                  open={openActions}
                  onClose={handleCloseActions}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  elevation={3}
                  sx={{
                    boxShadow:
                      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <MenuItem onClick={handleEdit}>
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    Editar
                  </MenuItem>
                  <MenuItem onClick={handleStatus}>
                    <ListItemIcon>
                      {credit?.status === "enabled" ? (
                        <OnIcon color="primary" />
                      ) : (
                        <OffIcon />
                      )}
                    </ListItemIcon>
                    {credit?.status === "enabled" ? "Desactivar" : "Activar"}
                  </MenuItem>
                  <MenuItem onClick={handleDelete}>
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    Eliminar
                  </MenuItem>
                </Menu>
              </Stack>
            </Card>
          );
        })}
      </Box>
    </ContainerContent>
  );
};

export default CreditList;
