import { PaymentIntentCredit } from "src/core/models/user_model";
import { LocalStorage } from "src/core/utils/localStorage";

export class PaymentBloc extends LocalStorage<PaymentIntentCredit> {
  payments: PaymentIntentCredit[] = [];

  async getPaymentIntent(
    credit_id?: string
  ): Promise<PaymentIntentCredit | undefined> {
    const payments = await this.getStoreArray("payment-intent");
    return payments.find((item) => item.credit_id === credit_id);
  }

  async addPaymentIntent(data: PaymentIntentCredit) {
    const payments = await this.getStoreArray("payment-intent");

    let items = payments.filter((item) => item.credit_id !== data.credit_id);

    items.push(data);
    this.setStore("payment-intent", items);
  }

  async removePaymentIntent(credit_id?: string) {
    const payments = await this.getStoreArray("payment-intent");

    let items = payments.filter((item) => item.credit_id !== credit_id);
    this.setStore("payment-intent", items);
  }
}
