import OrderService from "src/core/services/order_service";
import { Bloc } from "react-stream-bloc";
import { OrderInitialState, OrderState } from "./order_state";
import { Order } from "src/core/models/order_model";

export class OrderBloc extends Bloc<OrderState> {
  orders: Order[] = [];
  ordersAdmin: Order[] = [];

  constructor(private service: OrderService) {
    super(OrderInitialState);
    this.getOrders();
  }

  async getOrders() {
    this.changeState({ ...this.state, loading: true });
    const response = await this.service.getUserOrders();

    if (response.code === "success") {
      this.changeState(this.mapToLoadedState(response.data ?? []));
    } else {
      this.changeState(this.mapToLoadedState(this.orders));
    }
  }

  async getNextOrders() {
    this.changeState({ ...this.state, hasMore: true });
    const params = {
      skip: this.orders.length,
    };
    const response = await this.service.getUserOrders(params);

    if (response.code === "success") {
      const orders = response.data;
      if (orders) {
        const newOrders: Order[] = this.orders;
        await Promise.all(
          orders.map((v) => {
            const existedItem = newOrders.find((i) => i.id === v.id);

            if (!existedItem) {
              newOrders.push(v);
            }
            return true;
          })
        );
        this.changeState(this.mapToLoadedState(newOrders));
      } else {
        this.changeState({ ...this.state, hasMore: false });
      }
    } else {
      this.changeState({ ...this.state, hasMore: false });
    }
  }

  async getAdminOrders() {
    this.changeState({ ...this.state, loading: true });
    const response = await this.service.getOrders();

    if (response.code === "success") {
      this.ordersAdmin = response.data ?? [];
      this.changeState({
        ...this.state,
        dataAdmin: response.data ?? [],
        loading: false,
      });
    } else {
      this.changeState({ ...this.state, loading: false });
    }
  }

  async getNextAdminOrders() {
    this.changeState({ ...this.state, hasMore: true });
    const params = {
      skip: this.ordersAdmin.length,
    };
    const response = await this.service.getOrders(params);

    if (response.code === "success") {
      const orders = response.data;
      if (orders) {
        const newOrders: Order[] = this.ordersAdmin;
        await Promise.all(
          orders.map((v) => {
            const existedItem = newOrders.find((i) => i.id === v.id);

            if (!existedItem) {
              newOrders.push(v);
            }
            return true;
          })
        );
        this.ordersAdmin = newOrders;
        this.changeState({
          ...this.state,
          dataAdmin: newOrders,
          hasMore: false,
        });
      } else {
        this.changeState({ ...this.state, hasMore: false });
      }
    } else {
      this.changeState({ ...this.state, hasMore: false });
    }
  }

  async addOrder(data: Order): Promise<string | undefined> {
    this.changeState({ ...this.state, adding: true });
    const response = await this.service.addOrder(data);

    if (response.code === "success") {
      const order = response.data;
      if (order) {
        const newOrders = [order].concat(this.orders);

        this.changeState(this.mapToLoadedState(newOrders));
      } else {
        this.changeState({ ...this.state, adding: false });
      }
    } else {
      this.changeState(this.mapToLoadedState(this.orders));
    }
    return response.code;
  }

  async updateOrder(data: Order): Promise<string | undefined> {
    this.changeState({ ...this.state, updating: true });
    const response = await this.service.updateOrder(data.id, data);
    if (response.code === "success") {
      const client = response.data;
      const newOrders = this.orders.map((oldOrder) => {
        if (oldOrder.id === data.id) {
          return { ...oldOrder, client };
        } else {
          return oldOrder;
        }
      });
      this.changeState(this.mapToLoadedState(newOrders));
    } else {
      this.changeState(this.mapToLoadedState(this.orders));
    }
    return response.code;
  }

  async deleteOrder(id: string): Promise<string | undefined> {
    this.changeState({ ...this.state, deleting: true });
    const res = await this.service.deleteOrder(id);
    if (res.code === "success") {
      const newUsers = this.orders.filter((i) => i.id !== id);
      this.changeState(this.mapToLoadedState(newUsers));
    } else {
      this.changeState(this.mapToLoadedState(this.orders));
    }
    return res.code;
  }

  mapToLoadedState(data: Order[]): OrderState {
    this.orders = data;
    return {
      loading: false,
      adding: false,
      deleting: false,
      updating: false,
      hasMore: false,
      data: data,
      dataAdmin: this.ordersAdmin,
    };
  }
}
