import Container from "src/components/Container";
import { useState, useEffect } from "react";
import { UiDialog } from "src/components/Dialog";
import Content from "./components/Content";
import { Historial } from "src/core/models/request_model";
import HistoryList from "./components/List";
import { useHistorialBloc } from "src/core/provider/Provider";

import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { Box } from "@mui/system";
import { UiAutocomplete } from "src/components/Autocomplete";

const HistorialAllContent = () => {
  const bloc = useHistorialBloc();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [historial, setHistorial] = useState<Historial>();

  const initData = () => {
    if (bloc.historial.length === 0) {
      bloc.getHistorial();
    }
  };

  const handleEdit = (v: Historial) => {
    setHistorial(v);
    setOpenDialog(true);
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Container helmetTitle="Historial" title="Historial de carga del usuario">
      {/*<LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box display={"flex"} flexDirection="row">
          <MobileDatePicker defaultValue={dayjs(new Date())} />
        </Box>
  </LocalizationProvider> */}
      <HistoryList onEdit={handleEdit} bloc={bloc} />
      <UiDialog
        open={openDialog}
        setOpen={() => setOpenDialog(false)}
        maxWidth="lg"
      >
        <Content
          setOpen={(v: boolean) => setOpenDialog(v)}
          data={historial}
          bloc={bloc}
        />
      </UiDialog>
    </Container>
  );
};

export default HistorialAllContent;
