import HistorialService from "src/core/services/history_service";
import { Bloc } from "react-stream-bloc";
import { HistorialInitialState, HistorialState } from "./historial_state";
import { Historial } from "src/core/models/request_model";

export class HistorialBloc extends Bloc<HistorialState> {
  historial: Historial[] = [];
  historialSaved: Historial[] = [];

  constructor(private service: HistorialService) {
    super(HistorialInitialState);
    this.getHistorial();
  }

  async getHistorial() {
    this.changeState({ ...this.state, loading: true });
    const response = await this.service.getHistorialAll();

    if (response.code === "success") {
      this.changeState(this.mapToLoadedState(response.data ?? []));
    } else {
      this.changeState(this.mapToLoadedState(this.historial));
    }
  }

  async getNextHistorial() {
    this.changeState({ ...this.state, hasMore: true });
    const params = {
      skip: this.historial.length,
    };
    const response = await this.service.getHistorialAll(params);

    if (response.code === "success") {
      const resData = response.data;
      if (resData) {
        const newData: Historial[] = this.historial;
        await Promise.all(
          resData.map((v) => {
            const existedItem = newData.find((i) => i.id === v.id);

            if (!existedItem) {
              newData.push(v);
            }
            return true;
          })
        );
        this.changeState(this.mapToLoadedState(newData));
      } else {
        this.changeState({ ...this.state, hasMore: false });
      }
    } else {
      this.changeState({ ...this.state, hasMore: false });
    }
  }

  async getHistorialDetail(id?: string): Promise<Historial | undefined> {
    return this.historialSaved.find((item) => item.id === id);
  }

  setHistorialDetail(data: Historial) {
    let historial = data;

    const res = this.historial.find((item) => item.id === data.id);

    historial.user = res?.user;
    const newHistorial = [...this.historialSaved, data];
    this.historialSaved = newHistorial;
  }

  mapToLoadedState(data: Historial[]): HistorialState {
    this.historial = data;
    return {
      loading: false,
      adding: false,
      deleting: false,
      updating: false,
      hasMore: false,
      data: data,
    };
  }
}
