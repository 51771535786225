import * as React from "react";
import { useCreditBloc } from "src/core/provider/Provider";
import { Credit } from "src/core/models/credit_model";
import { Card, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import Empty from "src/core/components/Empty";
import { TextLabel } from "src/components/Label";
import { CreditState } from "src/core/bloc/credit/credit_state";
import { BlocBuilder } from "react-stream-bloc";
import { useState } from "react";
import { UiDialog } from "src/components/Dialog";
import BuyContent from "./BuyContent";
import ContainerContent from "src/components/Container/Content";
import UiButton from "src/components/Buttons";

const BuyCreditList = () => {
  const bloc = useCreditBloc();
  const [credit, setCredit] = useState<Credit>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleOpenDialog = (item: Credit) => {
    setCredit(item);
    setOpenDialog(true);
  };

  const handlePayClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <BlocBuilder
        bloc={bloc}
        builder={(state: CreditState) => {
          return state.data.filter((v) => v.status === "enabled").length ===
            0 ? (
            <Empty title="Créditos no disponibles" />
          ) : (
            <ContainerContent loading={state.loading}>
              <Grid
                container
                display="flex"
                flexWrap="wrap"
                spacing={2}
                marginTop={1}
              >
                {state.data
                  .filter((v) => v.status === "enabled")
                  .map((item: Credit) => {
                    return (
                      <Grid
                        key={item.id}
                        item
                        alignItems={"center"}
                        textAlign={"center"}
                        xs={undefined}
                      >
                        <Card
                          style={{
                            paddingBottom: 10,
                          }}
                        >
                          <Stack
                            direction="column"
                            minHeight={230}
                            width={150}
                            textAlign={"start"}
                          >
                            <Grid
                              container
                              direction="column"
                              spacing={1}
                              padding={2}
                            >
                              <Grid item xs={12}>
                                <TextLabel label="Nombre" text={item.name} />
                              </Grid>
                              <Grid item xs={12}>
                                <TextLabel
                                  label="Cantidad"
                                  text={item.amount}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextLabel
                                  label="Precio"
                                  text={"€ " + item.price}
                                />
                              </Grid>
                            </Grid>
                          </Stack>
                          <UiButton
                            text="Seleccionar"
                            onClick={() => handleOpenDialog(item)}
                          />
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            </ContainerContent>
          );
        }}
      />
      <UiDialog
        open={openDialog}
        title={credit !== undefined ? credit.name : undefined}
        maxWidth="lg"
      >
        <BuyContent data={credit} setOpen={setOpenDialog} />
      </UiDialog>
    </>
  );
};

export default BuyCreditList;
