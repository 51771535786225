import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { Button, Link, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import BoxedLayout from "src/core/components/BoxedLayout";
import { MuiForm } from "react-mui-form-validator";
import { UiTextField } from "src/components/TextField";
import UiLoadingButton from "src/components/LoadingButtons";
import { Title } from "src/components/Text";
import UserService from "src/core/services/user_service";
import { useTranslation } from "react-i18next";
import { isExpired } from "react-jwt";
import PasswordChecklist from "react-password-checklist";
import UiButton from "src/components/Buttons";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { token } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [code, setCode] = useState<string>();
  const [valid, setValid] = useState<boolean>(false);

  const handleChange = async () => {
    setLoading(true);
    if (valid) {
      if (token) {
        const res = await new UserService().resetPassword(token, password);
        if (res.code === "success") {
          navigate("/login");
        } else {
          setCode(res.code);
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);

  return (
    <BoxedLayout>
      {!isExpired(token ?? "") ? (
        <>
          <Title text="Cambiar contraseña" />
          <MuiForm onSubmit={handleChange}>
            {code ? (
              <div
                style={{
                  color: "white",
                  backgroundColor: "#FF0033",
                  width: "100%",
                  opacity: 0.8,
                  marginTop: 15,
                  marginBottom: 10,
                  textAlign: "center",
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingLeft: 15,
                  paddingRight: 15,
                  borderRadius: 15,
                }}
              >
                {t(`error.${code}`)}
              </div>
            ) : undefined}
            <UiTextField
              value={password}
              onChange={(v) => {
                setPassword(v.target.value);
              }}
              label="Nueva contraseña"
              placeholder="*******"
              fullWidth
              type="password"
              disabled={loading}
              validators={[
                {
                  validator: "required",
                },
              ]}
              errorMessage={[]}
              sx={{
                mt: 1,
              }}
            />
            <UiTextField
              value={passwordConfirm}
              onChange={(v) => setPasswordConfirm(v.target.value)}
              label="Confirmar nueva contraseña"
              placeholder="*******"
              fullWidth
              type="password"
              disabled={loading}
              validators={[
                {
                  validator: "required",
                },
              ]}
              errorMessage={[]}
              sx={{
                mt: 1,
                mb: 2,
              }}
            />

            <PasswordChecklist
              rules={["minLength", "number", "capital", "match"]}
              minLength={6}
              value={password}
              valueAgain={passwordConfirm}
              onChange={(v) => {
                setValid(v);
              }}
              messages={{
                minLength: "La contraseña tiene más de 6 caracteres.",
                number: "La contraseña tiene un número.",
                capital: "La contraseña tiene una letra mayúscula.",
                match: "Las contraseñas coinciden.",
              }}
            />

            <UiLoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loading={loading}
              disabled={!valid}
              sx={{ mt: 3 }}
              text="Cambiar contraseña"
            />
          </MuiForm>
        </>
      ) : (
        <Stack direction="column" alignItems="start">
          <div
            style={{
              color: "#FF0033",
              opacity: 0.8,
              paddingTop: 2,
              paddingBottom: 2,
              fontSize: 22,
              fontWeight: "bold",
            }}
          >
            Su token ha caducado.
          </div>
        </Stack>
      )}
      <Link href="/" underline="none">
        <UiButton
          variant="text"
          text="Ir a Inicio"
          sx={{ mt: 2, padding: 0 }}
        />
      </Link>
    </BoxedLayout>
  );
};

export default ChangePassword;
