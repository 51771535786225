import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import BoxedLayout from "src/core/components/BoxedLayout";
import { useAuthBloc } from "src/core/provider/Provider";
import UiButton from "src/components/Buttons";
import UserService from "src/core/services/user_service";
import { Title } from "src/components/Text";
import { Stack } from "@mui/system";
import { isExpired } from "react-jwt";
import Container from "src/components/Container";
import { Link } from "@mui/material";

const VerifyAccount = () => {
  const bloc = useAuthBloc();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const [code, setCode] = useState<string>();
  const { token } = useParams();

  const handleChange = async () => {
    setLoading(true);
    const expired = isExpired(token ?? "");
    if (!expired) {
      const res = await new UserService().verifyAccount(token);
      if (res.code === "success") {
        setCode(res.code);
        const getToken = localStorage.getItem("m-access-id");
        if (getToken) bloc.setAuth(res.data);
      } else {
        setCode(res.code);
      }
    } else {
      setCode("expired");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
    handleChange();
  }, []);

  const Message = (): React.JSX.Element => {
    switch (code) {
      case "success":
        return (
          <Stack
            direction="column"
            alignItems="center"
            sx={{
              mt: 2,
              mb: 2,
            }}
            textAlign="center"
          >
            <Title text="Su cuenta ha sido verificada correctamente" />
          </Stack>
        );
      case "expired":
        return (
          <Stack direction="column" alignItems="start">
            <Title text="Token de verificación ha caducado" />
            <div
              style={{
                color: "#FF0033",
                opacity: 0.8,
                paddingTop: 2,
                paddingBottom: 2,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Su token ha caducado, por favor reenvíe la verificación.
            </div>
          </Stack>
        );
      default:
        return (
          <Stack direction="column" alignItems="start">
            <Title text="Error de verificación" />
            <div
              style={{
                color: "#FF0033",
                opacity: 0.8,
                paddingTop: 2,
                paddingBottom: 2,
                borderRadius: 15,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Se ha producido un problema durante la verificación, inténtelo de
              nuevo.
            </div>
          </Stack>
        );
    }
  };

  return (
    <Container loading={loading}>
      <BoxedLayout>
        {code ? <Message /> : undefined}

        <Link href="/" underline="none">
          <UiButton variant="text" text="Ir a Inicio" sx={{ mt: 2, padding: 0 }} />
        </Link>
      </BoxedLayout>
    </Container>
  );
};

export default VerifyAccount;
