import { Dashboard } from "src/core/models/response_model";
import { User } from "src/core/models/user_model";

export type AuthState = {
  loading: boolean | false;
  login: boolean | false;
  register: boolean | false;
  updating: boolean | false;
  deleting: boolean | false;
  dashboard?: Dashboard;
  user?: User;
  token?: string;
  code?: string;
  message?: string;
};

export const AuthInitialState: AuthState = {
  loading: false,
  register: false,
  login: false,
  deleting: false,
  updating: false
};
