import * as React from "react";
import { useState } from "react";
import { OrderListProps } from "src/core/types/list_type";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Order } from "src/core/models/order_model";
import {
  Card,
  CircularProgress,
  Grid,
  MenuItem,
} from "@mui/material";
import { Stack } from "@mui/system";
import Empty from "src/core/components/Empty";
import { TextLabel } from "src/components/Label";
import ContainerContent from "src/components/Container/Content";
import CheckCircle from "@mui/icons-material/CheckCircle";
import ErrorCircle from "@mui/icons-material/RemoveCircle";
import InfiniteScroll from "react-infinite-scroll-component";
import { BlocBuilder } from "react-stream-bloc";
import { OrderState } from "src/core/bloc/order/order_state";

const OrderList = (props: OrderListProps) => {
  const { onEdit, bloc } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [credit, setOrder] = useState<Order>();

  const openActions = Boolean(anchorEl);

  const handleOpenActions = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: Order
  ) => {
    setOrder(item);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setAnchorEl(null);
    if (credit) onEdit(credit);
  };

  const handleDelete = () => {
    setAnchorEl(null);
    if (credit) bloc.deleteOrder(credit.id ?? "");
  };

  return (
    <BlocBuilder
      bloc={bloc}
      builder={(state: OrderState) => {
        return (
          <ContainerContent loading={state.loading}>
            {state.dataAdmin.length !== 0 ? (
              <InfiniteScroll
                dataLength={state.dataAdmin.length}
                next={() => bloc.getNextAdminOrders()}
                hasMore={state.hasMore ? false : true} // Replace with a condition based on your data source
                loader={<></>}
              >
                <Box
                  marginBottom={2}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {state.dataAdmin.map((item: Order) => {
                    return (
                      <Card
                        sx={{
                          width: "100%",
                        }}
                        key={item.id}
                        style={{ marginTop: 10 }}
                      >
                        <Stack direction="row">
                          <Grid
                            container
                            direction="row"
                            spacing={1}
                            padding={2}
                          >
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                              <TextLabel
                                label="Usuario"
                                text={item.user?.name}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                              <TextLabel label="Codigo" text={item.code} />
                            </Grid>
                            <Grid item xs={4} sm={2} md={2} lg={2}>
                              <TextLabel label="Cantidad" text={item.amount} />
                            </Grid>
                            <Grid item xs={4} sm={2} md={2} lg={2}>
                              <TextLabel
                                label="Precio"
                                text={"€ " + item?.price}
                              />
                            </Grid>
                            <Grid item xs={4} sm={2} md={2} lg={2}>
                              <TextLabel label="Estado de pago">
                                {item.status === "succeeded" ? (
                                  <CheckCircle color="primary" />
                                ) : (
                                  <ErrorCircle color="error" />
                                )}
                              </TextLabel>
                            </Grid>
                          </Grid>
                          {/*<IconButton
                    id="user-row-menu-button"
                    aria-label="user actions"
                    aria-controls="user-row-menu"
                    aria-haspopup="true"
                    style={{
                      maxHeight: 45,
                      maxWidth: 45,
                    }}
                    aria-expanded={openActions ? "true" : "false"}
                    disabled={loading}
                    onClick={(v) => handleOpenActions(v, item)}
                  >
                    <MoreVertIcon />
                  </IconButton>*/}
                          <Menu
                            id="user-row-menu"
                            anchorEl={anchorEl}
                            aria-labelledby="user-row-menu-button"
                            open={openActions}
                            onClose={handleCloseActions}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem onClick={handleEdit}>
                              <ListItemIcon>
                                <EditIcon />
                              </ListItemIcon>
                              Editar
                            </MenuItem>
                            <MenuItem onClick={handleDelete}>
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              Eliminar
                            </MenuItem>
                          </Menu>
                        </Stack>
                      </Card>
                    );
                  })}
                  {state.hasMore ? (
                    <Box
                      alignItems="center"
                      justifyItems="center"
                      textAlign="center"
                      marginTop={1}
                    >
                      <CircularProgress size={27} />
                    </Box>
                  ) : undefined}
                </Box>
              </InfiniteScroll>
            ) : (
              <Empty title="Aún no tienes pedidos" />
            )}
          </ContainerContent>
        );
      }}
    />
  );
};

export default OrderList;
