import { User } from "src/core/models/user_model";

export type UserState = {
  loading: boolean | false;
  adding: boolean | false;
  updating: boolean | false;
  deleting: boolean | false;
  searching: boolean;
  hasMore: boolean;
  data: User[] | [];
  filtered: User[]
  code?: string;
  message?: string;
};

export const UserInitialState: UserState = {
  loading: false,
  adding: false,
  updating: false,
  deleting: false,
  hasMore: false,
  searching: false,
  filtered: [],
  data: [],
};
