import { useEffect, useState } from "react";
import { MuiForm } from "react-mui-form-validator";
import {
  Box,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { UiTextField } from "src/components/TextField";
import { useSnackbar } from "src/core/contexts/SnackbarProvider";
import UiButton from "src/components/Buttons";
import { LineContentProps } from "src/core/types/content_type";
import { useLineBloc } from "src/core/provider/Provider";
import { useTranslation } from "react-i18next";
import { BlocBuilder } from "react-stream-bloc";
import { LineState } from "src/core/bloc/line/line_state";
import UiLoadingButton from "src/components/LoadingButtons";
import useWindowDimensions from "src/core/utils/dimensions";
import DeleteIcon from "@mui/icons-material/RemoveRounded";
import RemoveIcon from "@mui/icons-material/DeleteRounded";
import AddIcon from "@mui/icons-material/Add";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import { TextLabel } from "src/components/Label";
import { Container as ContainerDialog } from "@mui/material";
import TextSnippetIcon from "@mui/icons-material/TextSnippetRounded";
import { Stack } from "@mui/system";
import { useStyles } from "../styles/style";
import { UserLine } from "src/core/models/line_model";
import { UserLineState } from "src/core/bloc/user_line/user_line_state";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const LineContent = (props: LineContentProps) => {
  const { setOpen, bloc, userBloc } = props;
  const snackbar = useSnackbar();
  const classes = useStyles();
  const { t } = useTranslation();
  const { width, height } = useWindowDimensions();

  const [lines, setLines] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [userLineId, setUserLineId] = useState<string>();

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop: (files) => onDrop(files),
      accept: { "text/csv": [".csv"] },
    });

  const handleAdd = async () => {
    if (lines) {
      const res = await bloc.addLine(userLineId ?? "", lines);

      if (res === "success") {
        snackbar.success(`Se agregaron ${lines.length} líneas correctamente.`);
      } else {
        if (res !== undefined) {
          snackbar.error(t(`error.${res}`));
        }
      }
      setOpen(false);
    }
  };

  const onChangeLine = (v: any, index: number) => {
    const newNumbers = lines.map((c, i) => {
      if (i === index) {
        return v.target.value;
      } else {
        return c;
      }
    });
    setLines(newNumbers);
  };

  const addTextField = () => {
    setLines([...lines, ""]);
  };

  const onDrop = async (files: File[]) => {
    setErrorMessage(undefined);
    const file = files[0];

    if (!file)
      return setErrorMessage(
        "¡Formato de archivo incorrecto!. Por favor seleccione un archivo .CSV"
      );

    // Initialize a reader which allows user
    // to read any file or blob.
    const reader = new FileReader();

    // Event listener on reader when the file
    // loads, we parse it and set the data.
    reader.onload = async ({ target }: any) => {
      const csv = Papa.parse(target.result, {
        header: true,
        delimiter: ",",
      });
      const parsedData: any[] = csv?.data;
      let importIds: string[] = [];
      await Promise.all(
        parsedData.map((v) => {
          const rows = Object.keys(v);
          const columns = Object.values(v);
          const res: any[] = rows.reduce((acc: any, e, i) => {
            return [...acc, [[e], columns[i]]];
          }, []);

          const ids = res[0][1] as string;

          const data = lines.find((item) => item === ids);
          if (data) {
            setLines(lines.filter((v) => v !== ids));
          }
          if (ids.length > 6) importIds.push(ids);
        })
      );
      setLines(importIds);
    };
    reader.readAsText(file);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setUserLineId(event.target.value);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        justifyItems="center"
        style={{
          width: "100%",
        }}
        paddingBottom={1}
      >
        <Typography variant="h5">Agregar ID de linea</Typography>
        <Stack direction="row" alignItems="end">
          {lines.length > 0 ? (
            <IconButton
              color="error"
              aria-label="Eliminar lineas"
              onClick={() => setLines([])}
              style={{
                marginRight: 5,
              }}
            >
              <RemoveIcon />
            </IconButton>
          ) : undefined}
          <IconButton
            color="primary"
            aria-label="Agregar numeros"
            onClick={addTextField}
          >
            <AddIcon />
          </IconButton>
        </Stack>
      </Box>
      <Box
        sx={{
          mt: 2,
          mb: 2,
        }}
      >
        <TextLabel label="Seleccione un usuario" />
        <BlocBuilder
          bloc={userBloc}
          builder={(state: UserLineState) => (
            <Select
              labelId="users-select-small-label"
              id="users-select-small"
              fullWidth
              value={userLineId}
              onChange={handleChange}
              input={<OutlinedInput label="Name" />}
              MenuProps={MenuProps}
            >
              {state.data.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.username}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Box>
      {errorMessage ? <TextLabel error={errorMessage} /> : undefined}
      <BlocBuilder
        bloc={bloc}
        builder={(state: LineState) => (
          <MuiForm
            onSubmit={handleAdd}
            style={{
              width: width > 600 ? 450 : undefined,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxHeight: height - 330,
                overflow: "hidden",
                overflowY: "scroll",
              }}
            >
              <Grid container spacing={1} alignItems="center">
                {lines.map((v, index) => {
                  return (
                    <Grid item key={index} xs={6} sm={4}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignContent="center"
                        alignItems="center"
                      >
                        <UiTextField
                          key={index}
                          value={v}
                          placeholder="1234567"
                          number
                          required
                          fullWidth
                          onChange={(e: any) => onChangeLine(e, index)}
                          validators={[
                            { validator: "required" },
                            { validator: "minStringLength", min: 6 },
                            { validator: "maxStringLength", max: 10 },
                          ]}
                          errorMessage={[
                            "Ingrese el ID",
                            "ID invalido!",
                            "ID invalido!",
                          ]}
                        />
                        <IconButton
                          color="error"
                          aria-label="Aliminar numero"
                          onClick={() =>
                            setLines(lines.filter((v, i) => i !== index))
                          }
                          style={{
                            height: 30,
                            width: 30,
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
              <div
                className={classes.drag}
                {...getRootProps({ isFocused, isDragAccept, isDragReject })}
                style={{
                  borderColor: `${
                    isFocused
                      ? "#2196f3"
                      : isDragAccept
                      ? "#00e676"
                      : isDragReject
                      ? "#ff1744"
                      : "#eeeeee"
                  }`,
                }}
              >
                <input {...getInputProps()} />
                <TextLabel label="Arrastre y suelte su archivo .csv o haga clic para seleccionar" />
              </div>

              <Box display="flex" flexDirection="row" sx={{ mt: 1 }}>
                <Link href="/csv/Formato ID.csv" underline="hover">
                  Descargar formato
                </Link>
                <TextSnippetIcon color="primary" />
              </Box>
            </Box>
            <Grid container paddingTop={3} spacing={2}>
              <Grid item xs={6} textAlign="center">
                <UiButton
                  fullWidth
                  variant="outlined"
                  text="Cancelar"
                  onClick={() => setOpen(false)}
                />
              </Grid>
              <Grid item xs={6} textAlign="center">
                <UiLoadingButton
                  loading={state.adding}
                  fullWidth
                  disabled={
                    lines.length === 0
                      ? true
                      : userLineId === undefined
                      ? true
                      : undefined
                  }
                  text={"Agregar"}
                  type="submit"
                />
              </Grid>
            </Grid>
          </MuiForm>
        )}
      />
    </>
  );
};

export default LineContent;
