import { Theme } from '@mui/material';
import { makeStyles } from 'mui-styles';

const useStyles = makeStyles((theme: Theme) => ({
  headerTitle: {
    paddingTop: 32,
    paddingBottom: 24,
    paddingLeft: 32,
    paddingRight: 32,
    fontSize: 24,
    fontWeight: 500,
    color: '#171d29',
    fontStyle: 'normal',
    borderBottom: '1px solid #dee7ee'
  },
  content: {
    paddingTop: 35,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 56,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 10,
      paddingRight: 10
    }
  },
  table: {
    width: '100%'
  },
  sectionTitle: {
    padding: '8px 0',
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
    fontStyle: 'normal',
    color: '#767786',
    textTransform: 'uppercase'
  },
  cancelButton: {
    // width: "100%",
    // height: 45,
    // cursor: "pointer",
    // border: "1px solid #767786",
    // "& span": {
    //   fontFamily: "Roboto",
    //   fontSize: 18,
    //   fontWeight: 500,
    //   textAlign: "center",
    //   color: "#767786",
    //   fontStyle: "normal",
    // },
    width: 176,
    height: 48,
    //width: '100%',
    background: '#4E7D96',
    border: '2px solid #4E7D96',
    boxSizing: 'border-box',
    borderRadius: '40px',
    boxShadow: '0px 8px 24px -8px rgba(78, 125, 150, 0.24)',
    '& .MuiButton-label': {
      fontSize: 16,
      color: 'white',
      fontWeight: 'bold',
      textAlign: 'center',
      fontStyle: 'normal'
    },
    '&:hover': {
      backgroundColor: '#395C6F'
    },
    '&:hover .MuiButton-label': {}
  },
  cancelSubscription: {
    width: 248,
    height: 40,
    marginRight: 10,
    borderRadius: 40,
    backgroundColor: '#FF844B',
    boxShadow: '0px 4px 8px rgba(255, 72, 0, 0.24)',
    border: 'none',
    '& .MuiButton-label': {
      fontSize: 16,
      color: 'white',
      fontWeight: 'bold',
      textAlign: 'center',
      fontStyle: 'normal'
    },
    '&:hover': {
      backgroundColor: '#FF6D00'
    },
    '&:hover .MuiButton-label': {
      //color:'#8c8c8c',
    }
  },
  submitButton: {
    // width: "100%",
    // height: 45,
    // borderRadius: 16,
    // "& span": {
    //   fontFamily: "Roboto",
    //   fontSize: 18,
    //   fontWeight: 500,
    //   textAlign: "center",
    //   color: "#fff",
    //   fontStyle: "normal",
    // },
    //width: 176,
    height: 48,
    width: '100%',
    background: '#FF844B',
    border: '2px solid #FF844B',
    boxSizing: 'border-box',
    borderRadius: '40px',
    boxShadow: '0px 4px 8px rgba(255, 72, 0, 0.24)',
    '& .MuiButton-label': {
      fontSize: 16,
      color: 'white',
      fontWeight: 'bold',
      textAlign: 'center',
      fontStyle: 'normal'
    },
    '&:hover': {
      backgroundColor: '#FF6D00'
    },
    '&:hover .MuiButton-label': {}
  },
  buttonOk: {
    width: 140,
    height: 42,
    marginLeft: 5,
    background: '#FF844B',
    border: '2px solid #FF844B',
    boxSizing: 'border-box',
    borderRadius: '40px',
    boxShadow: '0px 4px 8px rgba(255, 72, 0, 0.24)',
    '& .MuiButton-label': {
      fontSize: 16,
      color: 'white',
      fontWeight: 'bold',
      textAlign: 'center',
      fontStyle: 'normal'
    },
    '&:hover': {
      backgroundColor: '#FF6D00'
    },
    '&:hover .MuiButton-label': {}
  },
  submitButtonDelete: {
    height: 48,
    width: '100%',
    background: '#FF606B',
    border: '2px solid #FF606B',
    boxSizing: 'border-box',
    borderRadius: '40px',
    boxShadow: '0px 4px 8px rgba(255, 72, 0, 0.24)',
    '& .MuiButton-label': {
      fontSize: 16,
      color: 'white',
      fontWeight: 'bold',
      textAlign: 'center',
      fontStyle: 'normal'
    },
    '&:hover': {
      backgroundColor: '#E84753'
    },
    '&:hover .MuiButton-label': {}
  },
  textBoxInit: {
    marginTop: 10,
    '& .MuiInputBase-root': {
      width: 'auto',
      height: 56,
      borderRadius: 6,
      backgroundColor: '#fff'
    },
    '& .MuiOutlinedInput-input': {
      padding: '19px 16px',
      fontFamily: 'Roboto',
      fontSize: 16,
      color: '#171d29',
      fontWeight: 400,
      fontStyle: 'normal'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#767786'
    },
    '& .MuiInputAdornment-positionEnd': {
      marginRight: 10
    }
  },
  textBox: {
    marginTop: 20,
    '& .MuiInputBase-root': {
      width: 'auto',
      height: 56,
      borderRadius: 6,
      backgroundColor: '#fff'
    },
    '& .MuiOutlinedInput-input': {
      padding: '19px 16px',
      fontFamily: 'Roboto',
      fontSize: 16,
      color: '#171d29',
      fontWeight: 400,
      fontStyle: 'normal'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#767786'
    },
    '& .MuiInputAdornment-positionEnd': {
      marginRight: 10
    }
  },
  error: {
    marginBottom: 20,
    fontFamily: 'Roboto',
    color: 'red'
  },
  dialog: {
    //'& .MuiDialog-paperWidthSm': {
    //  maxWidth: 450
    //},
    //'& .MuiDialog-paper': {
    //  width: 450
    //},
    '& .MuiDialogTitle-root': {
      paddingLeft: 25,
      paddingRight: 25,
      paddingTop: 20,
      paddingBottom: 10,
      fontSize: 18,
      fontWeight: 600,
      fontStyle: 'normal',
      color: '#171d29',
      '&>div:nth-child(2)': {
        color: '#667085',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal'
      }
    },

    [theme.breakpoints.down('xs')]: {
      '& .MuiDialog-paperWidthSm': {
        width: '100%'
      },
      '& .MuiDialogContent-root': {
        padding: 20
      }
    }
  },
  dialogSubscriptionCancel: {
    //"& .MuiBackdrop-root": {
    //  backgroundColor: "#171d29e6",
    //},
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 684
    },
    '& .MuiDialog-paper': {
      width: 684
    },
    '& .MuiDialogTitle-root': {
      paddingLeft: 32,
      paddingRight: 32,
      paddingTop: 18,
      paddingBottom: 18,
      borderBottom: '1px solid #76778633',
      fontSize: 24,
      fontWeight: 500,
      fontStyle: 'normal',
      color: '#171d29'
    },
    '& .MuiDialogContent-root': {
      padding: '24px 32px'
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiDialog-paperWidthSm': {
        width: '100%'
      },
      '& .MuiDialogContent-root': {
        padding: 20
      }
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(2),
    color: theme.palette.grey[800]
  },
  dialogCancelSuscription: {
    //"& .MuiBackdrop-root": {
    //  backgroundColor: "#35454C",
    //},
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 592,
      height: 680
    },
    '& .MuiDialog-paper': {
      width: 592,
      height: 'auto'
    },
    '& .MuiDialogTitle-root': {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: '700',
      color: '#3B5D6E',
      padding: '32px 32px',
      textAlign: 'center',
      '&>div:nth-child(1)': {
        width: '100%'
      },
      '&>div:nth-child(2)': {
        borderBottom: '1px solid #E3EBF1',
        width: '100%',
        height: '20px'
      }
    },
    '& .MuiDialogContent-root': {
      padding: '0px 40px 32px 40px',
      boxSizing: 'border-box'
    }
  },
  dialogDelete: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 592,
      height: 680
    },
    '& .MuiDialog-paper': {
      width: 350,
      height: 'auto'
    },
    '& .MuiDialogTitle-root': {
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: '500',
      padding: '25px 25px',
      textAlign: 'center',
      '&>div:nth-child(1)': {
        width: '100%'
      },
      '&>div:nth-child(2)': {
        borderBottom: '1px solid #E3EBF1',
        width: '100%',
        height: '20px'
      }
    },
    '& .MuiDialogContent-root': {
      padding: '0px 20px 20px 20px',
      boxSizing: 'border-box'
    }
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    fontStyle: 'normal',
    color: '#3B5D6E'
  },
  list: {
    listStyle: 'none',
    fontWeight: 600,
    '&>li': {
      display: 'flex',
      marginLeft: '-40px',
      '&>span': {
        color: '#FF844B',
        fontSize: 15
      },
      '&>p': {
        textAlign: 'justify',
        margin: 0
      }
    }
  },

  // Invoice

  borderInvoice: {
    boxSizing: 'border-box',
    width: '100%',
    height: 'auto',
    padding: 30
  },
  headerInvoice: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerInvoiceDate: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    color: '#3B5D6E'
  },
  headerInvoicePrice: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 24,
    color: '#3B5D6E',
    margin: '0 !important',
    padding: 0
  },
  headerInvoiceNumber: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    color: '#3B5D6E',
    marginTop: 10
  },
  tableInvoice: {
    '& .MuiTableCell-root': {
      border: 'none',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 12,
      borderTop: '1px solid #BECDD4'
    }
  },
  tableInvoiceTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    color: '#98ABB6'
  },
  [theme.breakpoints.down('xs')]: {
    cancelSubscription: {
      width: 'auto'
    }
  }
}));

export default useStyles;
