import axios from "axios";
import {
  ResponseCredit,
  ResponseCredits,
  ResponseMyCredit,
} from "../models/response_model";
import { Credit, CreditUser } from "../models/credit_model";

class CreditService {
  async getCredits(filter?: string): Promise<ResponseCredits> {
    let result: ResponseCredits = {};

    await axios
      .get("credits")
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async searchCredits(query: string) {
    let result: ResponseCredits = {};

    await axios
      .get(`credits/search`, {
        params: {
          query: query,
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getCredit(id: string) {
    let result: ResponseCredit = {};

    await axios
      .get(`credit/${id}`)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getMyCredit() {
    let result: ResponseMyCredit = {};

    await axios
      .get(`my/credit`)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async addCredit(data: Credit) {
    let result: ResponseCredit = {};

    await axios
      .post("credit", data)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        console.log(err);
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async addUserCredit(userId?: string, amount?: number) {
    let result: ResponseCredit = {};

    await axios
      .put(`user/credit`, {
        user_id: userId,
        amount: amount,
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async updateCredit(id?: string, data?: Credit) {
    let result: ResponseCredit = {};

    await axios
      .put(`credit/${id}`, data)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async updateStatusCredit(id?: string, status?: string) {
    let result: ResponseCredit = {};

    await axios
      .get(`credit/${id}/${status}`)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async deleteCredit(id?: string) {
    let result: ResponseCredit = {};

    await axios
      .delete(`credit/${id}`)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }
}

export default CreditService;
