import { Recharge } from "src/core/models/request_model";

export type RequestState = {
  loading: boolean | false;
  adding: boolean | false;
  updating: boolean | false;
  deleting: boolean | false;
  hasMore: boolean;
  data: Recharge[] | [];
  code?: string;
  message?: string;
};

export const RequestInitialState: RequestState = {
  loading: false,
  adding: false,
  updating: false,
  deleting: false,
  hasMore: false,
  data: [],
};
