import Box, { BoxProps } from "@mui/material/Box";
import LogoApp from "../assets/images/logo.png";

type LogoProps = {
  colored?: boolean;
  size?: number;
} & BoxProps;

const Logo = ({ colored = false, ...boxProps }: LogoProps) => {
  return (
    <Box padding={0} margin={0} {...boxProps}>
      <img alt="Marketaria" src={LogoApp} height={50} width={200} />
    </Box>
  );
};

export default Logo;
