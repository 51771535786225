import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Link, Typography } from "@mui/material";
import { useSnackbar } from "src/core/contexts/SnackbarProvider";
import { ReactNode, useState } from "react";
import BoxedLayout from "src/core/components/BoxedLayout";
import { MuiForm } from "react-mui-form-validator";
import { UiTextField } from "src/components/TextField";
import { useAuthBloc } from "src/core/provider/Provider";
import UiLoadingButton from "src/components/LoadingButtons";
import UiButton from "src/components/Buttons";
import UserService from "src/core/services/user_service";
import { Title } from "src/components/Text";
import { Stack } from "@mui/system";
import CheckRounded from "@mui/icons-material/CheckRounded";

const ForgotPassword = () => {
  const bloc = useAuthBloc();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();
  const [code, setCode] = useState<string>();

  const handleChange = async () => {
    setLoading(true);
    const res = await new UserService().forgotPassword(email);
    setCode(res.code);
    setLoading(false);
  };

  const onChangeEmail = (v: any) => {
    setCode(undefined);
    setEmail(v.target.value);
  };

  const Message = (): React.JSX.Element => {
    switch (code) {
      case "success":
        return (
          <Stack
            direction="column"
            alignItems="center"
            sx={{
              mt: 2,
              mb: 2,
            }}
          >
            <div style={{ textAlign: "center" }}>
              <a>Se ha enviado un enlace a su correo electrónico:</a>
              <a style={{ marginLeft: 10, fontWeight: "bold" }}>{email}</a>
            </div>
            <CheckRounded color="primary" style={{ height: 30 }} />
          </Stack>
        );
      default:
        return (
          <div
            style={{
              color: "white",
              backgroundColor: "#FF0033",
              opacity: 0.8,
              marginTop: 15,
              paddingTop: 2,
              paddingBottom: 2,
              paddingLeft: 15,
              paddingRight: 15,
              borderRadius: 15,
            }}
          >
            {t(`error.${code}`)}
          </div>
        );
    }
  };

  return (
    <BoxedLayout>
      <Title text="¿Olvidaste tu contraseña?" />
      {code === "success" ? undefined : (
        <Typography marginTop={3} textAlign="center">
          Para obtener un código de validación, ingrese la dirección de correo
          electrónico asociada con su cuenta.
        </Typography>
      )}
      {code ? <Message /> : undefined}
      {code === "success" ? undefined : (
        <MuiForm
          onSubmit={handleChange}
          sx={{ mt: 1 }}
          style={{
            maxWidth: 320,
            width: "100vw",
            padding: 10,
          }}
        >
          <UiTextField
            value={email}
            label="Correo electrónico"
            placeholder="ejemplo@marketaria.com"
            onChange={onChangeEmail}
            type="email"
            validators={[
              {
                validator: "required",
              },
              {
                validator: "isEmail",
              },
            ]}
            fullWidth
            errorMessage={[
              "Ingrese su correo electrónico",
              "¡Correo electrónico invalido!",
            ]}
          />

          <UiLoadingButton
            type="submit"
            fullWidth
            variant="contained"
            text="Enviar"
            loading={loading}
            sx={{ mt: 2 }}
          />
        </MuiForm>
      )}
      <Link href="/" underline="none">
        <UiButton
          variant="text"
          text="Ir a Inicio"
          sx={{ mt: 2, padding: 0 }}
        />
      </Link>
    </BoxedLayout>
  );
};

export default ForgotPassword;
