import RequestService from "src/core/services/request_service";
import { Bloc } from "react-stream-bloc";
import { RequestInitialState, RequestState } from "./request_state";
import { Recharge } from "src/core/models/request_model";
import { RequestRecharge } from "src/core/interface/request_interface";

export class RequestBloc extends Bloc<RequestState> {
  request_recharges: Recharge[] = [];

  constructor(private service: RequestService) {
    super(RequestInitialState);
    this.getRequests();
  }

  async getRequests() {
    this.changeState({ ...this.state, loading: true });
    const response = await this.service.getRequests();

    if (response.code === "success") {
      this.changeState(this.mapToLoadedState(response.data ?? []));
    } else {
      this.changeState(this.mapToLoadedState(this.request_recharges));
    }
  }

  async getRequestsHistory(historyId?: string) {
    if (historyId) {
      const response = await this.service.getRequestsHistorial();

      if (response.code === "success") {
        const data = response.data;
        if (data) {
          const requests = [...data].concat(this.request_recharges);
          this.changeState(this.mapToLoadedState(requests));
        }
      }
    }
  }

  async getNextRequests() {
    this.changeState({ ...this.state, hasMore: true });
    const params = {
      skip: this.request_recharges.length,
    };
    const response = await this.service.getRequests(params);

    if (response.code === "success") {
      const resData = response.data;
      if (resData) {
        const newData: Recharge[] = this.request_recharges;
        await Promise.all(
          resData.map((v) => {
            const existedItem = newData.find((i) => i.id === v.id);

            if (!existedItem) {
              newData.push(v);
            }
            return true;
          })
        );
        this.changeState(this.mapToLoadedState(newData));
      }else{
        this.changeState({ ...this.state, hasMore: false });
      }
    } else {
      this.changeState({ ...this.state, hasMore: false });
    }
  }

  async addRequest(data: RequestRecharge): Promise<string | undefined> {
    this.changeState({ ...this.state, adding: true });
    const response = await this.service.addRequest(data);

    if (response.code === "success") {
      const client = response.data;
      if (client) {
        const newRequests = [...client].concat(this.request_recharges);

        this.changeState(this.mapToLoadedState(newRequests));
      } else {
        this.changeState({ ...this.state, adding: false });
      }
      return response.code;
    } else {
      this.changeState({ ...this.state, adding: false });
      return response.code;
    }
  }

  async updateRequest(data: Recharge): Promise<boolean> {
    this.changeState({ ...this.state, updating: true });
    const response = await this.service.updateRequest(data.id, data);
    if (response.code === "success") {
      const client = response.data;
      const newRequests = this.request_recharges.map((oldRequest) => {
        if (oldRequest.id === data.id) {
          return { ...oldRequest, client };
        } else {
          return oldRequest;
        }
      });
      this.changeState(this.mapToLoadedState(newRequests));
      return true;
    } else {
      this.changeState(this.mapToLoadedState(this.request_recharges));
      return false;
    }
  }

  async deleteRequest(id: string) {
    this.changeState({ ...this.state, deleting: true });
    const res = await this.service.deleteRequest(id);
    if (res.code === "success") {
      const newUsers = this.request_recharges.filter((i) => i.id !== id);
      this.changeState(this.mapToLoadedState(newUsers));
    } else {
      this.changeState(this.mapToLoadedState(this.request_recharges));
    }
  }

  mapToLoadedState(data: Recharge[]): RequestState {
    this.request_recharges = data;
    return {
      loading: false,
      adding: false,
      deleting: false,
      updating: false,
      hasMore: false,
      data: data,
    };
  }
}
