import { useState } from "react";
import { MuiForm } from "react-mui-form-validator";
import { Grid } from "@mui/material";
import { UiTextField } from "src/components/TextField";
import { useSnackbar } from "src/core/contexts/SnackbarProvider";
import UiButton from "src/components/Buttons";
import { OrderContentProps } from "src/core/types/content_type";
import { useOrderBloc } from "src/core/provider/Provider";
import { Order } from "src/core/models/order_model";
import { useTranslation } from "react-i18next";

const OrderContent = (props: OrderContentProps) => {
  const { setOpen, data } = props;
  const bloc = useOrderBloc();
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const [client, setOrder] = useState<Order | undefined>(data);
  const [amount, setAmount] = useState<number | undefined>(data?.amount);

  const handleAddUpdateOrder = async () => {
    setOrder({
      amount: amount != undefined ? Number(amount) : 0,
    });

    if (data) {
      if (client) {
        const res = await bloc.updateOrder(client);
        if (res) {
          setOpen(false);
          snackbar.success("Se actualizo correctamente");
        }
      }
    } else {
      if (client) {
        const res = await bloc.addOrder(client);
        if (res) {
          setOpen(false);
          snackbar.success("Se agrego correctamente");
        }
      }
    }
  };

  return (
    <MuiForm onSubmit={handleAddUpdateOrder}>
      <Grid container spacing={2}>
        <Grid item lg>
          <UiTextField
            value={amount}
            label="Cantidad"
            placeholder="0"
            onChange={(e: any) => setAmount(e.target.value)}
            required
            errorMessage="Ingrese nombre del cliente"
          />
        </Grid>
      </Grid>

      <Grid container paddingTop={3} spacing={2}>
        <Grid item xs={6} textAlign="center">
          <UiButton
            fullWidth
            variant="outlined"
            text="Cancelar"
            onClick={() => setOpen(false)}
          />
        </Grid>
        <Grid item xs={6} textAlign="center">
          <UiButton
            fullWidth
            text={data ? "Actualizar" : "Agregar"}
            type="submit"
          />
        </Grid>
      </Grid>
    </MuiForm>
  );
};

export default OrderContent;
