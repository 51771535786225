import { createContext } from "react-stream-bloc";
import { CreditBloc } from "../bloc/credit/credit_bloc";
import { RequestBloc } from "../bloc/request/request_bloc";
import { OrderBloc } from "../bloc/order/order_bloc";
import { UserBloc } from "../bloc/user/user_bloc";
import { AuthBloc } from "../bloc/auth/auth_bloc";
import { LineBloc } from "../bloc/line/line_bloc";
import { HistoryBloc } from "../bloc/history/history_bloc";
import { MyCreditBloc } from "../bloc/myCredit/my_credit_bloc";
import { UserLineBloc } from "../bloc/user_line/user_line_bloc";
import { HistorialBloc } from "../bloc/historial/historial_bloc";

const [authContext, useAuth] = createContext<AuthBloc>();
const useAuthBloc = useAuth;

export { authContext, useAuthBloc };

const [creditContext, useCredit] = createContext<CreditBloc>();
const useCreditBloc = useCredit;

export { creditContext, useCreditBloc };

const [myCreditContext, useMyCredit] = createContext<MyCreditBloc>();
const useMyCreditBloc = useMyCredit;

export { myCreditContext, useMyCreditBloc };

const [requestContext, useRequest] = createContext<RequestBloc>();
const useRequestBloc = useRequest;

export { requestContext, useRequestBloc };

const [orderContext, useOrder] = createContext<OrderBloc>();
const useOrderBloc = useOrder;

export { orderContext, useOrderBloc };

const [userContext, useUser] = createContext<UserBloc>();
const useUserBloc = useUser;

export { userContext, useUserBloc };

const [lineContext, useLine] = createContext<LineBloc>();
const useLineBloc = useLine;

export { lineContext, useLineBloc };

const [userLineContext, useUserLine] = createContext<UserLineBloc>();
const useUserLineBloc = useUserLine;

export { userLineContext, useUserLineBloc };

const [historyContext, useHistory] = createContext<HistoryBloc>();
const useHistoryBloc = useHistory;

export { historyContext, useHistoryBloc };

const [historialContext, useHistorial] = createContext<HistorialBloc>();
const useHistorialBloc = useHistorial;

export { historialContext, useHistorialBloc };
