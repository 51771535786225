import * as React from "react";
import { RequestListProps } from "src/core/types/list_type";
import { Box, Card, CircularProgress, Grid } from "@mui/material";
import Empty from "src/core/components/Empty";
import { TextLabel } from "src/components/Label";
import { Recharge } from "src/core/models/request_model";
import ContainerContent from "src/components/Container/Content";
import Moment from "react-moment";
import { BlocBuilder } from "react-stream-bloc";
import { RequestState } from "src/core/bloc/request/request_state";
import InfiniteScroll from "react-infinite-scroll-component";

const RequestList = ({ bloc, onEdit }: RequestListProps) => {
  return (
    <BlocBuilder
      bloc={bloc}
      builder={(state: RequestState) => {
        return (
          <ContainerContent loading={state.loading}>
            {state.data.length !== 0 ? (
              <InfiniteScroll
                dataLength={state.data.length}
                next={() => bloc.getNextRequests()}
                hasMore={state.hasMore ? false : true} // Replace with a condition based on your data source
                loader={<></>}
              >
                <Grid
                  container
                  display="flex"
                  flexWrap="wrap"
                  spacing={2}
                  marginTop={2}
                >
                  {state.data.map((item: Recharge) => {
                    return (
                      <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                        <Card>
                          <Grid
                            container
                            direction="row"
                            spacing={2}
                            padding={2}
                          >
                            <Grid item xs={8}>
                              <TextLabel label="Numero" text={item.number} />
                            </Grid>
                            <Grid item xs={4}>
                              <TextLabel
                                label="Creditos"
                                text={item.used_credits ?? 0}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextLabel
                                label={
                                  <Moment format="LLL a" >
                                    {item.created_at}
                                  </Moment>
                                }
                              />
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    );
                  })}
                  {state.hasMore ? (
                    <Box
                      alignItems="center"
                      justifyItems="center"
                      textAlign="center"
                      marginTop={1}
                    >
                      <CircularProgress size={27} />
                    </Box>
                  ) : undefined}
                </Grid>
              </InfiniteScroll>
            ) : (
              <Empty title="Aún no tienes solicitudes" />
            )}
          </ContainerContent>
        );
      }}
    />
  );
};

export default RequestList;
