import axios from "axios";
import {
  Dashboard,
  ResponseDashboard,
  ResponseData,
  ResponseUser,
  ResponseUserData,
  ResponseUserUpdate,
  ResponseUsers,
} from "../models/response_model";
import { User } from "../models/user_model";

class UserService {
  async getloginUser() {
    let result: ResponseUserData = {};

    await axios
      .get("user")
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async loginUser(params: any) {
    let result: ResponseUser = {};

    await axios
      .post("user/login", params)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getUsers(filter?: any) {
    let result: ResponseUsers = {};

    await axios
      .get("users", {
        params: filter,
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getDashboard() {
    let result: ResponseDashboard = {};

    await axios
      .get("dashboard")
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async searchUsers(query: string) {
    let result: ResponseUsers = {};

    await axios
      .get("search/users", {
        params: { query: query },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async addUser(data: User) {
    let result: ResponseUser = {};

    await axios
      .post("user", data)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getUserTeamSelect(limit?: number) {
    let result;

    await axios
      .get("user/get/team/select", {
        params: {
          limit: limit,
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getUserData() {
    let result: ResponseUser = {};

    await axios
      .get("user")
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getUserTeam(limit?: number) {
    let result: ResponseUsers | any;

    await axios
      .get("user/team", {
        params: {
          limit: limit,
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getUsersRecent(limit?: number) {
    let result;

    await axios
      .get("users/recent", {
        params: {
          limit: limit,
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async updateUser(data?: User) {
    let result: ResponseUserUpdate = {};
    //const config = { headers: { "Content-Type": "multipart/form-data" } };

    await axios
      .put(`user`, data)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async updateStatusUser(id?: string, status?: string) {
    let result: ResponseUserUpdate = {};

    await axios
      .put(`user/status`, {
        user_id: id,
        status: status,
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async updateRoleUser(id?: string, role?: string) {
    let result: ResponseUserUpdate = {};

    await axios
      .put(`user/role`, {
        user_id: id,
        role: role,
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async forgotPassword(email?: string) {
    let result: ResponseData = {};
    await axios
      .get("user/forgot", {
        params: {
          email: email,
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async resetPassword(token?: string, newPass?: string) {
    let result: ResponseData = {};

    await axios
      .post(`user/forgot/${token}`, { password: newPass })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async changePassword(currentPass?: string, newPass?: string) {
    let result: ResponseData = {};

    await axios
      .put(`user/change/password`, {
        password: currentPass,
        new_password: newPass,
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        console.log(err);
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async verifyAccount(token?: string) {
    let result: ResponseData = {};

    await axios
      .get(`user/verify/${token}`)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async sendVerifyLink(email?: string) {
    let result: ResponseData = {};

    await axios
      .get(`user/verify/send/${email}`)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async deleteUser() {
    let result: ResponseUser = {};

    await axios
      .delete("user")
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async deleteUserMember(id: string) {
    let result: ResponseData = {};

    await axios
      .delete("user/delete/membe", {
        params: {
          member_id: id,
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }
}

export default UserService;
