import * as React from "react";
import { useOrderBloc } from "src/core/provider/Provider";
import { Box, Card, Chip, CircularProgress, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import Empty from "src/core/components/Empty";
import { TextLabel } from "src/components/Label";
import { BlocBuilder } from "react-stream-bloc";
import { OrderState } from "src/core/bloc/order/order_state";
import { Order } from "src/core/models/order_model";
import { Title } from "src/components/Text";
import ContainerContent from "src/components/Container/Content";
import Moment from "react-moment";
import InfiniteScroll from "react-infinite-scroll-component";

interface myCreditListProps {
  buy: boolean;
}

const MyCreditList = () => {
  const bloc = useOrderBloc();

  const initData = () => {
    if (bloc.orders.length === 0) {
      bloc.getOrders();
    }
  };
  React.useEffect(() => {
    initData();
  }, []);

  const getStatus = (status?: string): React.JSX.Element => {
    switch (status) {
      case "succeeded":
        return <Chip label="Completado" variant="outlined" color="primary" />;
      case "canceled":
        return <Chip label="Cancelado" variant="outlined" color="error" />;
      case "requires_capture":
        return <Chip label="Procesando" variant="outlined" color="info" />;
      case "processing":
        return <Chip label="Procesando" variant="outlined" color="warning" />;
      case "requires_action":
        return (
          <Chip label="Requiere acción" variant="outlined" color="warning" />
        );
      case "requires_confirmation":
        return (
          <Chip
            label="Requiere confirmación"
            variant="outlined"
            color="warning"
          />
        );
      default:
        return <Chip label="Creado" variant="outlined" />;
    }
  };

  return (
    <BlocBuilder
      bloc={bloc}
      builder={(state: OrderState) => {
        return (
          <ContainerContent loading={state.loading}>
            {state.data.length !== 0 ? (
              <InfiniteScroll
                dataLength={state.data.length}
                next={() => bloc.getNextOrders()}
                hasMore={state.hasMore ? false : true} // Replace with a condition based on your data source
                loader={<></>}
                
              >
                <Grid container display="flex" flexDirection="column">
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    justifyItems="center"
                    style={{
                      width: "100%",
                      marginTop: 10,
                    }}
                  >
                    <Title text="Historial de creditos" />
                  </Box>
                  <Grid container display="flex" flexWrap="wrap" spacing={2} marginTop={1}>
                    {state.data.map((item: Order) => {
                      return (
                        <Grid key={item.id} item>
                          <Card >
                            <Stack direction="column">
                              <Grid
                                container
                                direction="column"
                                spacing={1}
                                padding={2}
                              >
                                <Grid item xs={12}>
                                  <TextLabel label="Codigo" text={item.code} />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextLabel
                                    label="Cantidad"
                                    text={item.amount}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextLabel
                                    label="Precio"
                                    text={"€ " + item.price}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  {getStatus(item.status)}
                                </Grid>
                                <Grid item xs={12}>
                                  <TextLabel
                                    label={
                                      <Moment format="LLL a">
                                        {item.created_at}
                                      </Moment>
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Stack>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                {state.hasMore ? (
                  <Box
                    alignItems="center"
                    justifyItems="center"
                    textAlign="center"
                    marginTop={1}
                    marginBottom={1}
                  >
                    <CircularProgress size={27} />
                  </Box>
                ) : undefined}
              </InfiniteScroll>
            ) : (
              <Empty title="Aún no tienes creditos" />
            )}
          </ContainerContent>
        );
      }}
    />
  );
};

export default MyCreditList;
