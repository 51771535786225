import { Credit } from "src/core/models/credit_model";

export type CreditState = {
  loading: boolean | false;
  adding: boolean | false;
  updating: boolean | false;
  deleting: boolean | false;
  data: Credit[] | [];
  code?: string;
  message?: string;
};

export const CreditInitialState: CreditState = {
  loading: false,
  adding: false,
  updating: false,
  deleting: false,
  data: [],
};
