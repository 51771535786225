import React, { ReactNode } from "react";
import Lottie from "lottie-react";
import {
  Container as ContainerMaterial,
  Box,
  Theme,
  SxProps,
} from "@mui/material";
import LoadingAnimation from "src/assets/json/loading.json";

type Props = {
  children: React.ReactNode;
  loading?: boolean;
  sx?: SxProps<Theme> | undefined;
  onScroll?: React.UIEventHandler<HTMLDivElement>
};

const ContainerContent = (props: Props) => {
  const { children, loading, sx, onScroll } = props;
  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        height="100vh"
        sx={sx}
      >
        <Lottie
          style={{
            width: 110,
            height: 110,
          }}
          animationData={LoadingAnimation}
          loop={true}
        />
      </Box>
    );
  }

  return (
    <ContainerMaterial onScroll={onScroll} style={{ paddingTop: 0, padding: 0 }} sx={sx}>
      {children}
    </ContainerMaterial>
  );
};

export default ContainerContent;
