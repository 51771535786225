export abstract class LocalStorage<T> {
  getStore(key: string): T | null {
    const item = localStorage.getItem(key);
    return item ? (JSON.parse(item) as T) : null;
  }

  async getStoreArray(key: string): Promise<T[]> {
    const item = localStorage.getItem(key);
    return item ? (JSON.parse(item) as T[]) : [];
  }

  setStore(key: string, data: any): boolean {
    try {
      const value = JSON.stringify(data);
      localStorage.setItem(key, value);
      return true;
    } catch (e) {
      return false;
    }
  }

  removeStore(key: string): boolean {
    try {
      localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }

  clearStore() {
    localStorage.clear();
  }
}

export const getLocalStorage = (key: string): Promise<any> => {
  const item = window.localStorage.getItem(key);
  return item ? JSON.parse(item) : undefined;
};

export const setLocalStorage = (key: string, value: any): boolean => {
  try {
    const data = JSON.stringify(value);
    window.localStorage.setItem(key, data);
    return true;
  } catch (e) {
    return false;
  }
};

export const removeLocalStorage = (key: string): boolean => {
  try {
    window.localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

export const clearLocalStorage = (): any => {
  window.localStorage.clear();
};
