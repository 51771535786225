import Container from "src/components/Container";
import { BlocBuilder } from "react-stream-bloc";
import { useState, useEffect } from "react";
import { UiDialog } from "src/components/Dialog";
import Content from "./components/Content";
import { Container as ContainerDialog } from "@mui/material";
import { useOrderBloc } from "src/core/provider/Provider";
import { Order } from "src/core/models/order_model";
import OrderList from "./components/OrderList";

const OrderContent = () => {
  const bloc = useOrderBloc();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [clientData, setOrderData] = useState<Order>();

  const initData = () => {
    if (bloc.ordersAdmin.length === 0) {
      bloc.getAdminOrders();
    }
  };

  const handleEdit = (v: Order) => {
    setOrderData(v);
    setOpenDialog(true);
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Container helmetTitle="Pedidos" title="Pedidos">
      <OrderList bloc={bloc} onEdit={handleEdit} />
      <UiDialog
        open={openDialog}
        title={clientData ? "Actualizar cliente" : "Agregar nuevo cliente"}
        maxWidth="lg"
      >
        <ContainerDialog className="dialog-container">
          <Content
            setOpen={(v: boolean) => setOpenDialog(v)}
            data={clientData}
          />
        </ContainerDialog>
      </UiDialog>
    </Container>
  );
};

export default OrderContent;
