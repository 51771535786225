import { io } from "socket.io-client";

const API = process.env.REACT_APP_HOST_API ?? "";

export const socket = io(API, {
  withCredentials: true,
  transports: ["websocket", "polling"],
  autoConnect: false,
  forceNew: true,
});
