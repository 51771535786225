import * as React from "react";
import { useState } from "react";
import {
  HistorialListAllProps,
  HistorialListProps,
} from "src/core/types/list_type";
import { Historial } from "src/core/models/request_model";
import { Box, Card, CircularProgress, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import Empty from "src/core/components/Empty";
import { TextLabel } from "src/components/Label";
import { useStyles } from "./style";
import Moment from "react-moment";
import CheckIcon from "@mui/icons-material/CheckRounded";
import CloseIcon from "@mui/icons-material/CloseRounded";
import ContainerContent from "src/components/Container/Content";
import { BlocBuilder } from "react-stream-bloc";
import InfiniteScroll from "react-infinite-scroll-component";
import { HistorialState } from "src/core/bloc/historial/historial_state";

const HistorialList = ({ bloc, onEdit }: HistorialListAllProps) => {
  const classes = useStyles();

  return (
    <BlocBuilder
      bloc={bloc}
      builder={(state: HistorialState) => {
        return (
          <ContainerContent loading={state.loading}>
            {state.data.length !== 0 ? (
              <InfiniteScroll
                dataLength={state.data.length}
                next={() => bloc.getNextHistorial()}
                hasMore={state.hasMore ? false : true} // Replace with a condition based on your data source
                loader={<></>}
              >
                <Grid container spacing={2}>
                  {state.data.map((item: Historial) => {
                    return (
                      <Grid
                        item
                        key={item.id}
                        xs={12}
                        sm={6}
                        md={4}
                        onClick={() => {
                          onEdit(item);
                        }}
                      >
                        <Card className={classes.outlined} key={item.id}>
                          <Stack direction="row">
                            <Grid
                              container
                              direction="row"
                              spacing={0.2}
                              paddingLeft={2}
                              paddingRight={2}
                              paddingTop={2}
                              color="white"
                            >
                              <Grid item xs={12}>
                                <TextLabel
                                  label="Usuario"
                                  text={item.user?.name ?? 0}
                                  subText={item.user?.email ?? ""}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextLabel
                                  label="Numeros a cargar"
                                  text={item.amount_numbers ?? 0}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextLabel
                                  label="Cargas completas"
                                  text={item.complete_charge ?? 0}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextLabel
                                  label="Cargas parciales"
                                  text={item.partial ?? 0}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextLabel
                                  label="Cargas erroneas"
                                  text={item.error_charge ?? 0}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextLabel
                                  label="Cargas no realizadas"
                                  text={item.unrealized_charge ?? 0}
                                />
                              </Grid>

                              <Grid item xs={6}>
                                <TextLabel
                                  label="Creditos usados"
                                  text={item.used_credits ?? 0}
                                />
                              </Grid>
                              <Grid item xs={11}>
                                <TextLabel
                                  label={
                                    <Moment format="LLL a" >
                                      {item.created_at}
                                    </Moment>
                                  }
                                />
                              </Grid>
                              <Grid item xs={1} textAlign="end">
                                {item.status === "successful" ? (
                                  <CheckIcon color="primary" />
                                ) : (
                                  <CloseIcon />
                                )}
                              </Grid>
                            </Grid>
                          </Stack>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
                {state.hasMore ? (
                  <Box
                    alignItems="center"
                    justifyItems="center"
                    textAlign="center"
                    marginTop={1}
                  >
                    <CircularProgress size={27} />
                  </Box>
                ) : undefined}
              </InfiniteScroll>
            ) : (
              <Empty title="Aún no tienes historial" />
            )}
          </ContainerContent>
        );
      }}
    />
  );
};

export default HistorialList;
