import { User } from "src/core/models/user_model";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import EditIcon from "@mui/icons-material/EditRounded";
import OffIcon from "@mui/icons-material/ToggleOffRounded";
import OnIcon from "@mui/icons-material/ToggleOnRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Card, Grid, MenuItem } from "@mui/material";
import { Stack } from "@mui/system";
import { TextLabel } from "src/components/Label";
import { UserState } from "src/core/bloc/user/user_state";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { UserBloc } from "src/core/bloc/user/user_bloc";
import { useState } from "react";

interface ItemProps {
  user: User;
  bloc: UserBloc;
  state: UserState;
  onEdit: (value: User) => void;
  onAddCredit: (value?: User) => void;
}

const ItemContent = ({ user, bloc, state, onAddCredit, onEdit }: ItemProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openActions = Boolean(anchorEl);

  const handleOpenActions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setAnchorEl(null);
    if (user) onEdit(user);
  };

  const handleAddCredit = () => {
    setAnchorEl(null);
    if (user) onAddCredit(user);
  };

  const handleStatus = () => {
    setAnchorEl(null);
    if (user)
      bloc.updateStatusUser(
        user.uid,
        user.status === "active" ? "inactive" : "active"
      );
  };

  return (
    <Card key={user.uid} style={{ marginTop: 10, width: "100%" }}>
      <Stack direction="row">
        <Grid container direction="row" spacing={0.2} padding={2} color="white">
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextLabel label="Nombre" text={user.name} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextLabel
              label="Email / Teléfono"
              text={user.email}
              subText={user.phone}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <TextLabel
              label="Creditos"
              text={user.credits?.credits ?? 0}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <TextLabel
              label="Rol"
              text={user.role === "admin" ? "Admin" : "Operador"}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <TextLabel label="Estado">
              {user.status === "active" ? (
                <Chip style={{ maxWidth: 80 }} color="primary" label="Activo" />
              ) : (
                <Chip style={{ maxWidth: 80 }} label="Inactivo" />
              )}
            </TextLabel>
          </Grid>
        </Grid>
        <IconButton
          id="user-row-menu-button"
          aria-label="user actions"
          aria-controls="user-row-menu"
          aria-haspopup="true"
          style={{
            maxHeight: 45,
            maxWidth: 45,
          }}
          aria-expanded={openActions ? "true" : "false"}
          disabled={state.loading}
          onClick={(v) => handleOpenActions(v)}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="user-row-menu"
          anchorEl={anchorEl}
          aria-labelledby="user-row-menu-button"
          open={openActions}
          onClose={handleCloseActions}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={handleEdit}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            Ver detalles
          </MenuItem>
          <MenuItem onClick={handleStatus}>
            <ListItemIcon>
              {user?.status === "active" ? (
                <OnIcon color="primary" />
              ) : (
                <OffIcon />
              )}
            </ListItemIcon>
            {user?.status === "active" ? "Desactivar" : "Activar"}
          </MenuItem>
          <MenuItem onClick={handleAddCredit}>
            <ListItemIcon>
              <AccountBalanceWalletIcon />
            </ListItemIcon>
            Enviar créditos
          </MenuItem>
        </Menu>
      </Stack>
    </Card>
  );
};

export default ItemContent;
