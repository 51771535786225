import axios from "axios";
import { ResponseOrder, ResponseOrders } from "../models/response_model";
import { Order } from "../models/order_model";

class OrderService {
  async getOrders(filter?: any): Promise<ResponseOrders> {
    let result: ResponseOrders = {};

    await axios
      .get("orders", {
        params: filter,
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getUserOrders(filter?: any): Promise<ResponseOrders> {
    let result: ResponseOrders = {};

    await axios
      .get("user/orders", {
        params: filter,
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async searchOrders(query: string) {
    let result: ResponseOrders = {};

    await axios
      .get(`orders/search`, {
        params: {
          query: query,
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getOrder(id: string) {
    let result: ResponseOrder = {};

    await axios
      .get(`order/${id}`)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async addOrder(data: Order) {
    let result: ResponseOrder = {};

    await axios
      .post("order", data)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        console.log(err);
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async updateOrder(id?: string, data?: Order) {
    let result: ResponseOrder = {};

    await axios
      .put(`order/${id}`, data)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async deleteOrder(id?: string) {
    let result: ResponseOrder = {};

    await axios
      .delete(`order/${id}`)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }
}

export default OrderService;
