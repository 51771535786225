import { AuthBloc } from "../bloc/auth/auth_bloc";
import { CreditBloc } from "../bloc/credit/credit_bloc";
import { HistorialBloc } from "../bloc/historial/historial_bloc";
import { HistoryBloc } from "../bloc/history/history_bloc";
import { LineBloc } from "../bloc/line/line_bloc";
import { MyCreditBloc } from "../bloc/myCredit/my_credit_bloc";
import { OrderBloc } from "../bloc/order/order_bloc";
import { RequestBloc } from "../bloc/request/request_bloc";
import { UserBloc } from "../bloc/user/user_bloc";
import { UserLineBloc } from "../bloc/user_line/user_line_bloc";
import CreditService from "../services/credit_service";
import HistoryService from "../services/history_service";
import LineService from "../services/line_service";
import OrderService from "../services/order_service";
import RequestService from "../services/request_service";
import UserLineService from "../services/user_line_service";
import UserService from "../services/user_service";

export function providerAuthBloc(): AuthBloc {
  const bloc = new AuthBloc(new UserService()); //Init your values
  return bloc;
}

export function providerUserBloc(): UserBloc {
  const bloc = new UserBloc(new UserService()); //Init your values
  return bloc;
}

export function providerOrderBloc(): OrderBloc {
  const bloc = new OrderBloc(new OrderService()); //Init your values
  return bloc;
}

export function provideRequestBloc(): RequestBloc {
  const bloc = new RequestBloc(new RequestService()); //Init your values
  return bloc;
}

export function provideCreditBloc(): CreditBloc {
  const bloc = new CreditBloc(new CreditService()); //Init your values
  return bloc;
}

export function provideMyCreditBloc(): MyCreditBloc {
  const bloc = new MyCreditBloc(new CreditService()); //Init your values
  return bloc;
}

export function provideLineBloc(): LineBloc {
  const bloc = new LineBloc(new LineService()); //Init your values
  return bloc;
}

export function provideUserLineBloc(): UserLineBloc {
  const bloc = new UserLineBloc(new UserLineService()); //Init your values
  return bloc;
}

export function provideHistoryBloc(): HistoryBloc {
  const bloc = new HistoryBloc(new HistoryService()); //Init your values
  return bloc;
}

export function provideHistorialBloc(): HistorialBloc {
  const bloc = new HistorialBloc(new HistoryService()); //Init your values
  return bloc;
}
