import MyCreditService from "src/core/services/credit_service";
import { Bloc } from "react-stream-bloc";
import { MyCreditInitialState, MyCreditState } from "./my_credit_state";
import { UserCredit } from "src/core/models/credit_model";

export class MyCreditBloc extends Bloc<MyCreditState> {
  credit: UserCredit | undefined;

  constructor(private service: MyCreditService) {
    super(MyCreditInitialState);
    this.getMyCredit();
  }

  async getMyCredit() {
    this.changeState({ ...this.state, loading: true });
    const response = await this.service.getMyCredit();

    if (response.code === "success") {
      this.changeState(this.mapToLoadedState(response.data));
    } else {
      this.changeState(this.mapToLoadedState(this.credit));
    }
  }

  async updateOnCredit(data: UserCredit) {
    this.changeState(this.mapToLoadedState(data));
  }

  mapToLoadedState(data?: UserCredit): MyCreditState {
    this.credit = data;
    return {
      loading: false,
      data: data,
    };
  }
}
