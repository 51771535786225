import { CreditUser, UserCredit } from "src/core/models/credit_model";

export type MyCreditState = {
  loading: boolean | false;
  data?: UserCredit;
  code?: string;
  message?: string;
};

export const MyCreditInitialState: MyCreditState = {
  loading: false
};
