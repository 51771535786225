import axios from "axios";
import { ResponseData, ResponseUserLine, ResponseUserLines } from "../models/response_model";
import { UserLine } from "../models/line_model";

class UserLineService {
  async getUserLines(filter?: any): Promise<ResponseUserLines> {
    let result: ResponseUserLines = {};

    await axios
      .get("user_lines", {
        params: filter,
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getUserLine(id: string) {
    let result: ResponseUserLine = {};

    await axios
      .get(`user_line/${id}`)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async addUserLine(data?: UserLine) {
    let result: ResponseUserLine = {};

    await axios
      .post("user_line", data)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        console.log(err);
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async finetworkLoginTest(username: string, password: string): Promise<any> {
    let result: ResponseData = {};

    await axios
      .post("https://gateway-operators.finetwork.com/api/v2/token", {
        email: username,
        password: password,
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async updateUserLine(id?: string, data?: UserLine) {
    let result: ResponseUserLine = {};

    await axios
      .put(`user_line/${id}`, data)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async deleteUserLine(id?: string) {
    let result: ResponseUserLine = {};

    await axios
      .delete(`user_line/${id}`)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          code: "disconnected",
          message: "No internet connection",
        };
      });

    return result;
  }
}

export default UserLineService;
