import Container from "src/components/Container";
import { useState, useEffect } from "react";
import { UiDialog } from "src/components/Dialog";
import Content from "./components/Content";
import { Container as ContainerDialog } from "@mui/material";
import { useHistoryBloc } from "src/core/provider/Provider";
import { Historial } from "src/core/models/request_model";
import HistoryList from "./components/List";

const HistoryContent = () => {
  const bloc = useHistoryBloc();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [historial, setHistorial] = useState<Historial>();

  const initData = () => {
    if (bloc.historial.length === 0) {
      bloc.getHistorial();
    }
  };

  const handleEdit = (v: Historial) => {
    setHistorial(v);
    setOpenDialog(true);
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Container helmetTitle="Historial" title="Historial de cargas">
      <HistoryList onEdit={handleEdit} bloc={bloc} />
      <UiDialog
        open={openDialog}
        setOpen={() => setOpenDialog(false)}
        maxWidth="lg"
      >
        <Content
          setOpen={(v: boolean) => setOpenDialog(v)}
          data={historial}
          bloc={bloc}
        />
      </UiDialog>
    </Container>
  );
};

export default HistoryContent;
