import { useTranslation } from "react-i18next";
import { useAuthBloc } from "src/core/provider/Provider";
import { useState } from "react";
import { BlocBuilder } from "react-stream-bloc";
import { AuthState } from "src/core/bloc/auth/auth_state";
import { MuiForm } from "react-mui-form-validator";
import { UiTextField } from "src/components/TextField";
import { User } from "src/core/models/user_model";
import BoxedLayout from "src/core/components/BoxedLayout";
import { Box, Grid, Paper, Typography } from "@mui/material";
import UiLoadingButton from "src/components/LoadingButtons";
import UiButton from "src/components/Buttons";

const Register = () => {
  const bloc = useAuthBloc();
  const { t } = useTranslation();

  const [name, setName] = useState<string>("");
  const [nif, setNif] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [contactPerson, setContactPerson] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [code, setCode] = useState<string>();

  const handleAdd = async () => {
    const data: User = {
      name: name,
      address: address,
      nif: nif,
      contact_person: contactPerson,
      email: email,
      username: email,
      password: password,
      phone: phone,
    };

    const res = await bloc.register(data);
    if (res !== "success") {
      setCode(res);
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(./img/startup.svg)",
          backgroundRepeat: "no-repeat",
          bgcolor: "background.default",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} square>
        <BoxedLayout>
          <Typography component="h1" variant="h3">
            Crear cuenta
          </Typography>
          <BlocBuilder
            bloc={bloc}
            builder={(state: AuthState) => (
              <Box marginTop={1}>
                <MuiForm
                  onSubmit={handleAdd}
                  style={{
                    maxWidth: 320,
                    width: "100vw",
                  }}
                >
                  {code ? (
                    <div
                      style={{
                        color: "white",
                        backgroundColor: "#FF0033",
                        width: "100%",
                        opacity: 0.8,
                        marginTop: 15,
                        marginBottom: 10,
                        textAlign: "center",
                        paddingTop: 2,
                        paddingBottom: 2,
                        paddingLeft: 15,
                        paddingRight: 15,
                        borderRadius: 15,
                      }}
                    >
                      {t(`error.${code}`)}
                    </div>
                  ) : undefined}
                  <UiTextField
                    value={name}
                    onChange={(v) => setName(v.target.value)}
                    label="Nombre"
                    placeholder="Marketaria"
                    fullWidth
                    required
                    errorMessage="Ingrese un nombre"
                  />
                  <UiTextField
                    value={nif}
                    onChange={(v) => setNif(v.target.value)}
                    label="NIF"
                    placeholder="1234567"
                    fullWidth
                    sx={{
                      mt: 1,
                    }}
                  />
                  <UiTextField
                    value={address}
                    onChange={(v) => setAddress(v.target.value)}
                    label="Dirección"
                    placeholder=""
                    fullWidth
                    sx={{
                      mt: 1,
                    }}
                  />
                  <UiTextField
                    value={contactPerson}
                    onChange={(v) => setContactPerson(v.target.value)}
                    label="Persona de contacto"
                    placeholder="Marketaria"
                    fullWidth
                    sx={{
                      mt: 1,
                    }}
                  />
                  <UiTextField
                    value={phone}
                    onChange={(v) => setPhone(v.target.value)}
                    label="Telefono"
                    placeholder="123456789"
                    fullWidth
                    sx={{
                      mt: 1,
                    }}
                  />

                  <UiTextField
                    value={email}
                    onChange={(v) => setEmail(v.target.value)}
                    label="Correo electrónico"
                    placeholder="ejemplo@marketaria.com"
                    required
                    fullWidth
                    errorMessage={["Ingrese su correo electrónico"]}
                    sx={{
                      mt: 1,
                    }}
                  />

                  <UiTextField
                    value={password}
                    onChange={(v) => setPassword(v.target.value)}
                    label="Contraseña"
                    placeholder="*******"
                    type="password"
                    fullWidth
                    required
                    errorMessage={"Ingrese una contraseña"}
                    sx={{
                      mt: 1,
                    }}
                  />

                  <UiLoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    text="Crear cuenta"
                    loading={state.register}
                    sx={{ mt: 2 }}
                  />
                  <UiButton
                    text="Iniciar sesión"
                    to={"/login"}
                    variant="text"
                    fullWidth
                    sx={{
                      mt: 2,
                      mb: 2,
                    }}
                  />
                </MuiForm>
              </Box>
            )}
          />
        </BoxedLayout>
      </Grid>
    </Grid>
  );
};

export default Register;
