import { Theme } from "@mui/material";
import { makeStyles } from "mui-styles";

export const useStyles = makeStyles((theme: Theme) => ({
  outlined: {
    border: "1px solid #E3E6E8",
    boxSizing: "border-box",
    borderRadius: 20,
    cursor: "pointer",
    "& .MuiButton-label": {
      fontSize: 16,
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      fontStyle: "normal",
    },
    "&:hover": {
      backgroundColor: "rgba(0, 153, 102, 0.01)",
      boxShadow: "0px 4px 8px rgba(0, 153, 102, 0.24)",
    },
    "&:hover .MuiButton-label": {},
  },
}));
