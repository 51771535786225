import LineService from "src/core/services/line_service";
import { Bloc } from "react-stream-bloc";
import { LineInitialState, LineState } from "./line_state";
import { Line } from "src/core/models/line_model";

export class LineBloc extends Bloc<LineState> {
  lines: Line[] = [];

  constructor(private service: LineService) {
    super(LineInitialState);
    this.getLines();
  }

  async getLines() {
    this.changeState({ ...this.state, loading: true });
    const response = await this.service.getLines();

    if (response.code === "success") {
      this.changeState(this.mapToLoadedState(response.data ?? []));
    } else {
      this.changeState(this.mapToLoadedState(this.lines));
    }
  }

  async resetLines(): Promise<string | undefined> {
    this.changeState({ ...this.state, loading: true });
    const response = await this.service.resetLines();

    if (response.code === "success") {
      this.getLines();
    } else {
      this.changeState({ ...this.state, loading: false });
    }

    return response.code;
  }

  async getNextLines() {
    this.changeState({ ...this.state, hasMore: true });
    const params = {
      skip: this.lines.length,
    };
    const response = await this.service.getLines(params);

    if (response.code === "success") {
      const lines = response.data;
      if (lines) {
        const newLines: Line[] = this.lines;
        await Promise.all(
          lines.map((v) => {
            const existedItem = newLines.find((i) => i.line_id === v.line_id);

            if (!existedItem) {
              newLines.push(v);
            }
            return true;
          })
        );
        this.changeState(this.mapToLoadedState(newLines));
      }
    } else {
      this.changeState({ ...this.state, hasMore: false });
    }
  }

  async addLine(
    user_line_id: string,
    data: string[]
  ): Promise<string | undefined> {
    this.changeState({ ...this.state, adding: true });
    const response = await this.service.addLine(user_line_id, data);

    if (response.code === "success") {
      const lines = response.data;
      if (lines) {
        let newLines: Line[] = this.lines;
        await Promise.all(
          lines.map((v) => {
            const existedItem = newLines.find((i) => i.line_id === v.line_id);

            if (existedItem) {
              existedItem.used = v.used;
            } else {
              newLines.push(v);
            }
            return true;
          })
        );
        this.changeState(this.mapToLoadedState(newLines));
      } else {
        this.changeState({ ...this.state, adding: false });
      }
      return response.code;
    } else {
      this.changeState(this.mapToLoadedState(this.lines));
      return response.code;
    }
  }

  async updateLine(data: Line): Promise<string | undefined> {
    this.changeState({ ...this.state, updating: true });
    const response = await this.service.updateLine(data.line_id, data);
    if (response.code === "success") {
      const line = response.data;
      if (line) {
        const newLines = this.lines.map((oldLine) => {
          if (oldLine.line_id === line?.line_id) {
            return {
              ...oldLine,
              used: line.used,
              updated_at: line.updated_at,
            };
          } else {
            return oldLine;
          }
        });
        this.changeState(this.mapToLoadedState(newLines));
      }
      return response.code;
    } else {
      this.changeState(this.mapToLoadedState(this.lines));
      return response.code;
    }
  }

  async deleteLine(id: string) {
    this.changeState({ ...this.state, deleting: true });
    const res = await this.service.deleteLine(id);
    if (res.code === "success") {
      const newUsers = this.lines.filter((i) => i.line_id !== id);
      this.changeState(this.mapToLoadedState(newUsers));
    } else {
      this.changeState(this.mapToLoadedState(this.lines));
    }
  }

  mapToLoadedState(data: Line[]): LineState {
    this.lines = data;
    return {
      loading: false,
      adding: false,
      deleting: false,
      updating: false,
      hasMore: false,
      data: data,
    };
  }
}
