import React, { useEffect } from "react";
import AppRoutes from "./AppRoutes";
import Loader from "./core/components/Loader";
import QueryWrapper from "./core/components/QueryWrapper";
import SettingsProvider from "./core/contexts/SettingsProvider";
import SnackbarProvider from "./core/contexts/SnackbarProvider";
import { BlocProvider } from "react-stream-bloc";
import {
  provideCreditBloc,
  provideHistorialBloc,
  provideHistoryBloc,
  provideLineBloc,
  provideMyCreditBloc,
  provideRequestBloc,
  provideUserLineBloc,
  providerAuthBloc,
  providerOrderBloc,
  providerUserBloc,
} from "./core/provider/DependenciesProvider";
import {
  creditContext,
  userContext,
  orderContext,
  requestContext,
  authContext,
  lineContext,
  historyContext,
  myCreditContext,
  userLineContext,
  historialContext,
} from "./core/provider/Provider";

function App() {

  return (
    <React.Suspense fallback={<Loader />}>
      <SettingsProvider>
        <QueryWrapper>
          <SnackbarProvider>
            <BlocProvider
              providers={[
                <authContext.Provider value={providerAuthBloc()} />,
                <userContext.Provider value={providerUserBloc()} />,
                <myCreditContext.Provider value={provideMyCreditBloc()} />,
                <creditContext.Provider value={provideCreditBloc()} />,
                <orderContext.Provider value={providerOrderBloc()} />,
                <requestContext.Provider value={provideRequestBloc()} />,
                <lineContext.Provider value={provideLineBloc()} />,
                <userLineContext.Provider value={provideUserLineBloc()} />,
                <historyContext.Provider value={provideHistoryBloc()} />,
                <historialContext.Provider value={provideHistorialBloc()} />,
              ]}
            >
              <AppRoutes />
            </BlocProvider>
          </SnackbarProvider>
        </QueryWrapper>
      </SettingsProvider>
    </React.Suspense>
  );
}

export default App;
