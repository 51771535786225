import Container from "src/components/Container";
import { BlocBuilder } from "react-stream-bloc";
import { Title } from "src/components/Text";
import UiButton from "src/components/Buttons";
import { useState, useEffect } from "react";
import { UiDialog } from "src/components/Dialog";
import Content from "./components/Content";
import { Container as ContainerDialog } from "@mui/material";
import { Credit } from "src/core/models/credit_model";
import { CreditState } from "src/core/bloc/credit/credit_state";
import { useCreditBloc } from "src/core/provider/Provider";
import CreditList from "src/admin/pages/credit/components/CreditList";
import { Box } from "@mui/system";
import { useSnackbar } from "src/core/contexts/SnackbarProvider";

const CreditContent = () => {
  const bloc = useCreditBloc();
  const snackbar = useSnackbar();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [credit, setCredit] = useState<Credit>();

  const initData = () => {
    if (bloc.credits.length === 0) {
      bloc.getCredits();
    }
  };

  const handleAdd = () => {
    setCredit(undefined);
    setOpenDialog(true);
  };

  const handleEdit = (v: Credit) => {
    setCredit(v);
    setOpenDialog(true);
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Container helmetTitle="Creditos" title="Créditos">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="end"
        justifyItems="center"
        style={{
          width: "100%",
        }}
      >
        <UiButton text="Agregar credito" onClick={handleAdd} />
      </Box>
      <BlocBuilder
        bloc={bloc}
        builder={(state: CreditState) => {
          return (
            <CreditList
              loading={state.loading}
              data={state.data}
              onEdit={handleEdit}
            />
          );
        }}
      />
      <UiDialog
        open={openDialog}
        title={credit ? "Actualizar crédito" : "Agregar crédito"}
        maxWidth="lg"
      >
        <ContainerDialog className="dialog-container">
          <Content setOpen={(v: boolean) => setOpenDialog(v)} data={credit} />
        </ContainerDialog>
      </UiDialog>
    </Container>
  );
};

export default CreditContent;
